import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";

const Tarieven = (props) => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [naam, setNaam] = useState();
  const [email, setEmail] = useState();
  const [wachtwoord, setWachtwoord] = useState();
  const [modal1, setModal1] = useState();
  const history = useHistory();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const bodyParameters = {
    user_name: naam,
    email: email?.toLowerCase(),
    password: wachtwoord,
  };

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Naam",
      selector: "user_name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "",
      selector: "",
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="danger"
            size="sm"
            className="ml-2 px-2 py-2"
            onClick={() => handleOpenModal(row?.id)}
          >
            Verwijderen
          </Button>
        );
      },
      sortable: true,
      center: true,
      minWidth: "110px",
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="transparant"
            size="sm"
            className="ml-2 px-2 py-2"
            onClick={() => handleMoreInfo(row?.id)}
          >
            Meer info
          </Button>
        );
      },
      sortable: true,
      center: true,
      minWidth: "110px",
    },
  ];

  const getData = async () => {
    await axios
      .get(`${API_URL}/superadmin/leasecompanys`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  const handleNieuweLease = (e) => {
    e.preventDefault();

    axios
      .post(`${API_URL}/superadmin/create-lease`, bodyParameters, ApiConfig)
      .then(function (response) {
        setModal(false);
        getData();
        toast.success("Nieuwe partner is aangemaakt!");
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const handleDelete = () => {
    const leaseId = localStorage.getItem("lease");
    axios
      .delete(`${API_URL}/superadmin/lease-company-admin/${leaseId}`, ApiConfig)
      .then(function (response) {
        getData();
        toast.success("Partner is verwijderd");
        setModal1(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const handleOpenModal = (id) => {
    localStorage.setItem("lease", id);
    setModal1(true);
  };

  const handleMoreInfo = (id) => {
    localStorage.setItem("lease", id);
    history.push(`${process.env.PUBLIC_URL}/voertuigen/partners/detail`);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Voertuigen" title="Partners" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <span>Bekijk alle partners en beheer hun account</span>
              </CardHeader>
              <CardBody className="text-center">
                <Button
                  color="primary"
                  style={{ marginTop: 20, marginBottom: 20 }}
                  onClick={() => setModal(true)}
                >
                  Partner toevoegen
                </Button>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  empty
                  striped={true}
                  center={true}
                  pagination={true}
                  pointerOnHover={true}
                  persistTableHead
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn nog geen geregistreerde partners.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal1} toggle={() => setModal1(!modal1)}>
        <ModalHeader toggle={() => setModal(!modal1)}>
          Weet je zeker dat je deze lease maatschappij wilt verwijderen?
        </ModalHeader>
        <ModalFooter>
          <Button color="danger" onClick={() => handleDelete()}>
            Verwijderen
          </Button>{" "}
          <Button color="grey" onClick={() => setModal1(!modal1)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader toggle={() => setModal(false)}>
          Nieuwe partner account aanmaken
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form onSubmit={(e) => handleNieuweLease(e)}>
            <Row>
              <Col>
                <Input
                  className="form-control"
                  type="text"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  placeholder="Naam"
                  value={naam}
                  onChange={(e) => setNaam(e.target.value)}
                  required
                />

                <Input
                  className="form-control"
                  type="text"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 10,
                  }}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <Input
                  className="form-control"
                  type="password"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 10,
                  }}
                  placeholder="Wachtwoord"
                  value={wachtwoord}
                  onChange={(e) => setWachtwoord(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <Button color="primary" style={{ marginTop: 20 }} type="submit">
              Partner aanmaken
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Tarieven;
