import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import {
  Users,
  ShoppingBag,
  MessageCircle,
  UserPlus,
  BookOpen,
} from "react-feather";
import CountUp from "react-countup";
import { Send, Clock } from "react-feather";
import axios from "axios";
import { API_URL } from "../../constant";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import priceConverter from "../usePriceConverter";

const Default = (props) => {
  const [daytimes, setDayTimes] = useState();
  const [requests, setRequests] = useState([]);
  const [companyRequests, setCompanyRequests] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [users, setUsers] = useState();
  const [invoice, setInvoice] = useState();
  const [totalRequestCount, setTotalRequestCount] = useState();
  const [adminType, setAdminType] = useState();

  const today = new Date();
  const curHr = today.getHours();
  const curMi = today.getMinutes();
  const [meridiem, setMeridiem] = useState("AM");
  // eslint-disable-next-line
  const [date, setDate] = useState({ date: new Date() });
  // eslint-disable-next-line

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const getRequests = async () => {
    await axios
      .get(`${API_URL}/superadmin/recent-requests`, ApiConfig)
      .then((res) => {
        setRequests(res.data);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  const getCompanyRequests = async () => {
    await axios
      .get(`${API_URL}/superadmin/recent-requests-company`, ApiConfig)
      .then((res) => {
        setCompanyRequests(res.data);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  const getInvoices = async () => {
    await axios
      .get(`${API_URL}/invoice/expired-invoices`, ApiConfig)
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  const getUserCount = async () => {
    await axios
      .get(`${API_URL}/superadmin/count/users`, ApiConfig)
      .then((res) => {
        setUsers(parseInt(res.data[0].count));
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  const getRequestCount = async () => {
    await axios
      .get(`${API_URL}/superadmin/count/requests`, ApiConfig)
      .then((res) => {
        setTotalRequestCount(res.data.count);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  const getInvoiceCount = async () => {
    await axios
      .get(`${API_URL}/invoice/count/invoices`, ApiConfig)
      .then((res) => {
        setInvoice(parseInt(res.data[0].count));
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    if (curHr < 12) {
      setDayTimes("Goedemorgen");
    } else if (curHr < 18) {
      setDayTimes("Goedemiddag");
    } else {
      setDayTimes("Goedenavond");
    }

    if (curHr >= 12) {
      setMeridiem("PM");
    } else {
      setMeridiem("AM");
    }

    const type = localStorage.getItem("type");
    setAdminType(type);

    if (type == "Super Admin") {
      getRequestCount();
      getUserCount();
      getInvoiceCount();
      getInvoices();
      getRequests();
      getCompanyRequests();
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col lg="12" className="morning-sec box-col-12 w-100">
            <Card className="o-hidden profile-greeting">
              <CardBody className="greeting-card-body">
                <div className="media">
                  <div className="badge-groups w-100">
                    <div className="badge f-12">
                      <Clock
                        style={{ width: "16px", height: "16px" }}
                        className="mr-1"
                      />
                      <span id="txt">
                        {curHr}:{curMi < 10 ? "0" + curMi : curMi} {meridiem}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="greeting-user text-center">
                  <div className="profile-vector">
                    <img
                      className="img-fluid"
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png"
                      }
                      alt=""
                      width={120}
                      height={120}
                    />
                  </div>
                  <h4 className="f-w-600">
                    <span id="greeting">
                      {daytimes} {localStorage.getItem("username")}
                    </span>{" "}
                    <span className="right-circle">
                      <i className="fa fa-check-circle f-14 middle"></i>
                    </span>
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
          {localStorage.getItem("type") === "Super Admin" && (
            <>
              <Col sm="6" xl="4" lg="4" md="4">
                <Card className="o-hidden">
                  <CardBody className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Users />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Actieve gebruikers</span>
                        <h4 className="mb-0 counter">
                          <CountUp end={users ? users : null} />
                        </h4>
                        <Users className="icon-bg" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6" xl="4" lg="4" md="4">
                <Card className="o-hidden">
                  <div className="bg-secondary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <BookOpen />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Openstaande facturen</span>
                        <h4 className="mb-0 counter">
                          <CountUp end={invoice ? invoice : null} />
                        </h4>
                        <BookOpen className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              {/* 
              <Col sm="6" xl="3" lg="6">
                <Card className="o-hidden">
                  <CardBody className="bg-primary b-r-4">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <MessageCircle />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Nieuwe berichten</span>
                        <h4 className="mb-0 counter">
                          <CountUp end={100} />
                        </h4>
                        <MessageCircle className="icon-bg" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>*/}
              <Col sm="6" xl="4" lg="4" md="4">
                <Card className="o-hidden">
                  <CardBody className="bg-primary b-r-4">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <UserPlus />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Nieuwe aanvragen</span>
                        <h4 className="mb-0 counter">
                          <CountUp
                            end={totalRequestCount ? totalRequestCount : null}
                          />
                        </h4>
                        <UserPlus className="icon-bg" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6" lg="12 box-col-12 xl-100">
                <div>
                  <Card>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <h5 className="text-uppercase">Recente aanvragen</h5>
                        <Link
                          to="/klant/aanvragen"
                          className="btn btn-primary dashboard-bekijken-btn"
                        >
                          Bekijken
                        </Link>
                      </div>
                    </CardHeader>
                    <div
                      style={{ wordBreak: "break-all" }}
                      className="d-flex w-full"
                    >
                      <CardBody className="w-25">
                        <ul className="crm-activity">
                          {requests?.slice(0, 5).map((item, i) => {
                            return (
                              <li className="media" key={i}>
                                <div className="align-self-center media-body text-center text-md-left">
                                  <h6 className="mt-0">
                                    {item?.first_name} {item?.last_name}
                                    <br className="homepage-aanvragen-break" />
                                    {item?.first_name && " - Gebruiker"}
                                  </h6>
                                  <ul className="dates">
                                    <li className="digits">
                                      <p className="mb-2">Aanvraagdatum: </p>
                                      <div>
                                        <p className="d-block d-md-inline mb-0">
                                          {new Date(
                                            item?.created_at
                                          ).getDate() +
                                            "/" +
                                            (new Date(
                                              item?.created_at
                                            ).getMonth() +
                                              1) +
                                            "/" +
                                            new Date(
                                              item?.created_at
                                            ).getFullYear()}
                                        </p>
                                        <p className="d-md-inline mb-0"> </p>
                                        <p className="d-block d-md-inline">
                                          {new Date(
                                            item?.created_at
                                          ).getHours() +
                                            ":" +
                                            new Date(
                                              item?.created_at
                                            ).getMinutes() +
                                            ":" +
                                            new Date(
                                              item?.created_at
                                            ).getSeconds()}
                                        </p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            );
                          })}
                          {requests?.length === 0 &&
                            "Er zijn geen recente aanvragen voor gebruikers."}
                        </ul>{" "}
                      </CardBody>
                      <CardBody className="w-25">
                        <ul className="crm-activity">
                          {companyRequests?.slice(0, 5).map((item, i) => {
                            return (
                              <li className="media" key={i}>
                                <div className="align-self-center media-body text-center text-md-left">
                                  <h6 className="mt-0 crm-wrapper">
                                    {item?.name + " "}
                                    <br className="homepage-aanvragen-break" />
                                    {item?.name && "- Bedrijf"}
                                  </h6>
                                  <ul className="dates">
                                    <li className="digits">
                                      <p className="mb-2">Aanvraagdatum: </p>
                                      <div>
                                        <p className="d-block d-md-inline mb-0">
                                          {new Date(
                                            item?.created_at
                                          ).getDate() +
                                            "/" +
                                            (new Date(
                                              item?.created_at
                                            ).getMonth() +
                                              1) +
                                            "/" +
                                            new Date(
                                              item?.created_at
                                            ).getFullYear()}
                                        </p>
                                        <p className="d-md-inline mb-0"> </p>
                                        <p className="d-block d-md-inline">
                                          {new Date(
                                            item?.created_at
                                          ).getHours() +
                                            ":" +
                                            new Date(
                                              item?.created_at
                                            ).getMinutes() +
                                            ":" +
                                            new Date(
                                              item?.created_at
                                            ).getSeconds()}
                                        </p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            );
                          })}
                          {companyRequests?.length === 0 &&
                            "Er zijn geen recente aanvragen voor bedrijven."}
                        </ul>
                      </CardBody>
                    </div>
                  </Card>
                </div>
              </Col>
              <Col xl="6" lg="12 box-col-12 xl-100">
                <div>
                  <Card>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <h5 className="text-uppercase">
                          Facturen buiten betaaltermijn
                        </h5>
                        <Link
                          to="/klant/facturen"
                          className="btn btn-primary dashboard-bekijken-btn"
                        >
                          Bekijken
                        </Link>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ul className="crm-activity">
                        {invoices.slice(0, 5).map((item, i) => {
                          return (
                            <li className="media" key={i}>
                              <div className="align-self-center media-body">
                                <h6 className="mt-0">ID: {item?.id}</h6>
                                <p>Factuurnummer: {item?.pdfname}</p>
                                <p>Bedrag: {priceConverter(item?.amount)}</p>
                                <p>Datum: {item?.created_at}</p>
                                <p>Opleveringsdatum: {item?.dueDate}</p>
                                <p>
                                  Status:{" "}
                                  {item?.is_paid ? "Betaald" : "Niet Betaald"}
                                </p>
                                <p>Type: {item?.type}</p>
                                <ul className="dates">
                                  <li className="digits"></li>
                                </ul>
                              </div>
                            </li>
                          );
                        })}

                        {invoices.length === 0 &&
                          "Er zijn geen facturen buiten betaaltermijn."}
                      </ul>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Default;
