import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import differenceBy from "lodash/differenceBy";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constant";
import priceConverter from "../usePriceConverter";
import { CheckCircle, Edit } from "react-feather";

import { AiFillCar } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { FaFileInvoiceDollar, FaBuilding } from "react-icons/fa";

const Facturen = () => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [modalShow, setModalShow] = useState(false);

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const getData = async () => {
    axios
      .get(`${API_URL}/invoice/invoices`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan data niet ophalen.");
      });
  };

  const getPaidInvoices = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/invoice/paid-invoices`,
        ApiConfig
      );
      setData(res.data);
    } catch (e) {
      toast.error("Kan data niet ophalen");
    }
  };

  const getUnpaidInvoices = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/invoice/unpaid-invoices`,
        ApiConfig
      );
      setData(res.data);
    } catch (e) {
      toast.error("Kan data niet ophalen");
    }
  };

  const getExpiredInvoices = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/invoice/expired-invoices-filter`,
        ApiConfig
      );
      setData(res.data);
    } catch (e) {
      toast.error("Kan data niet ophalen");
    }
  };

  useEffect(() => {
    if (activeTab === "1") {
      getData();
    } else if (activeTab === "2") {
      getPaidInvoices();
    } else if (activeTab === "3") {
      getUnpaidInvoices();
    } else if (activeTab === "4") {
      getExpiredInvoices();
    }
  }, [activeTab]);

  useEffect(() => {
    getData();
  }, []);

  const approveInvoiceAsPaid = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/invoice/approve-invoice/${selectedInvoiceId}`,
        ApiConfig
      );
      toast.success("Factuur is succesvol goedgekeurd als Betaald.");
      getData();
      setModal(false);
    } catch (e) {
      toast.error("Factuur kan niet worden goedgekeurd.");
    }
  };

  const tableColumns = [
    {
      name: "Factuur ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Site",
      selector: (row) => {
        if (row?.site == 1) {
          return "Z";
        } else {
          return "P";
        }
      },
      sortable: true,
      center: true,
    },
    {
      name: "Aan",
      selector: (row) => row?.first_name + " " + row?.last_name,
      sortable: true,
      center: true,
    },
    {
      name: "Bedrag",
      selector: (row) => priceConverter(row?.amount) + " " + "€",
      sortable: true,
      center: true,
    },
    {
      name: "Datum",
      selector: "created_at",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Factuur PDF",
      cell: (row, index, column, id) => {
        return (
          <a
            className="btn btn-primary"
            download={`${row.first_name}_${row.last_name}_invoice_${row.invoice_key}`}
            href={`${API_URL}/invoice/download-invoice-admin/${row.invoice_key}`}
          >
            <i className="fa fa-download" style={{ color: "white" }}></i>
          </a>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      cell: (row, index, column, id) => {
        return row?.is_paid ? (
          <p
            style={{ minWidth: "100px" }}
            className="bg-success py-2 px-2 text-white rounded-lg text-center"
          >
            Betaald
          </p>
        ) : (
          <p
            style={{ minWidth: "100px" }}
            className="bg-danger py-2 px-2 text-white rounded-lg text-center"
            onClick={() => {
              setSelectedInvoiceId(row?.id);
              setModal(true);
            }}
          >
            Niet Betaald
            <Edit className="ml-3" style={{ height: "14px", width: "14px" }} />
          </p>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Facturen" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <p>Selecteer een factuur voor meer informatie.</p>
                <Link to={`${process.env.PUBLIC_URL}/klant/nieuwfactuur`}>
                  <Button color="primary">Nieuw factuur aanmaken</Button>
                </Link>
              </CardHeader>
              <CardBody>
                <Nav tabs className="search-list">
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => setActiveTab("1")}
                    >
                      <i className="icon-target"></i>
                      Alles
                    </NavLink>
                  </NavItem>
                  <NavItem className="bg-light-success">
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => setActiveTab("2")}
                    >
                      <i className="icon-image"></i>
                      Betaald
                    </NavLink>
                  </NavItem>
                  <NavItem className="bg-light-warning">
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => setActiveTab("3")}
                    >
                      <i className="icon-video-clapper"></i>
                      Niet betaald
                    </NavLink>
                  </NavItem>
                  <NavItem className="bg-light-danger">
                    <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={() => setActiveTab("4")}
                    >
                      <i className="icon-map-alt"></i>
                      Buiten betalingsperiode
                    </NavLink>
                  </NavItem>
                </Nav>

                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  pagination={true}
                  noDataComponent={
                    <p style={{ padding: 20 }}>Er zijn nog geen facturen.</p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          Wilt u de status van deze factuur wijzigen?
        </ModalHeader>
        <ModalBody>
          U kunt de status van uw factuur als betaald wijzigen door het
          goedkeuren van deze factuur.
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center align-items-center">
          <Button color="success" onClick={() => approveInvoiceAsPaid()}>
            Goedkeuren als betaald
          </Button>{" "}
          <Button color="light" onClick={() => setModal(!modal)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Facturen;
