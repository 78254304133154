import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import Pagination from "../Pagination";

const Company = (props) => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [modal, setModal] = useState(false);
  const [naam, setNaam] = useState("");
  const [kvk, setKvk] = useState("");
  const [btw, setBtw] = useState("");
  const [adres, setAdres] = useState("");
  const [postcode, setPostcode] = useState("");
  const [stad, setStad] = useState("");
  const [email, setEmail] = useState("");
  const [wachtwoord, setWachtwoord] = useState("");

  const history = useHistory();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };
  const adminType = localStorage.getItem("type");
  const adminId = localStorage.getItem("id");

  const bodyParameters = {
    name: naam,
    kvk,
    btw,
    email: email?.toLowerCase(),
    password: wachtwoord,
    zip_code: postcode,
    address: adres,
    city: stad,
    adminType: adminType ? adminType : null,
    adminId: adminId ? adminId : null,
  };

  const search = (value) => {
    filterData(value);
  };

  const excludeColumns = [
    "id",
    "aantalgebruikers",
    "address",
    "city",
    "image_url",
    "is_bvo",
    "latitude",
    "longitude",
  ];

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(allData);
    else {
      const filteredData = allData.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns?.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/admin/companies`, ApiConfig)
      .then((res) => {
        setData(res.data);
        setAllData(res.data);
      })
      .catch((e) => {
        toast.error(e.response.data.msg);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Bedrijfsnaam",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
  ];

  const handleRowClicked = (id) => {
    localStorage.setItem("userid", id);
    history.push(`${process.env.PUBLIC_URL}/klant/companydetails/${id}`);
  };

  const handleNieuweCompany = (e) => {
    //Todo: validation
    e.preventDefault();

    axios
      .post(`${API_URL}/company/register-ms4u`, bodyParameters, ApiConfig)
      .then(function (response) {
        setModal(false);
        getData();
        toast.success("Bedrijf is aangemaakt!");
      })
      .catch(function (error) {
        toast.error(`Er is een fout opgetreden. ${error.response.data.msg}`);
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Bedrijven" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{ padding: "15px" }}
                className="bg-light-primary"
              >
                <Form className="theme-form">
                  <InputGroup className="m-0">
                    <Input
                      onChange={(e) => search(e.target.value)}
                      className="form-control-plaintext px-3"
                      type="search"
                      placeholder="Zoeken op naam of email..."
                    />
                  </InputGroup>
                </Form>
              </CardHeader>
              <CardBody>
                <div className="w-100 d-flex flex-md-row flex-column justify-content-between">
                  <p className="text-md-left text-center">
                    Klik op een bedrijf om meer informatie te zien.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => setModal(true)}
                  >
                    Maak nieuwe bedrijf aan
                  </button>
                </div>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  empty
                  striped={true}
                  center={true}
                  pagination={true}
                  pointerOnHover={true}
                  persistTableHead
                  onRowClicked={(e) => handleRowClicked(e.id)}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen bedrijven met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader toggle={() => setModal(false)}>
          Nieuwe bedrijf aanmaken
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form onSubmit={(e) => handleNieuweCompany(e)}>
            <Row>
              <Col>
                <Input
                  className="form-control"
                  type="text"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  placeholder="Bedrijfsnaam"
                  value={naam}
                  onChange={(e) => setNaam(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Kvk"
                  value={kvk}
                  onChange={(e) => setKvk(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Btw"
                  value={btw}
                  onChange={(e) => setBtw(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Bedrijfsadres"
                  value={adres}
                  onChange={(e) => setAdres(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Postcode"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Plaats"
                  value={stad}
                  onChange={(e) => setStad(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="password"
                  placeholder="Wachtwoord"
                  value={wachtwoord}
                  onChange={(e) => setWachtwoord(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <Button color="primary" style={{ marginTop: 20 }} type="submit">
              Bedrijf aanmaken
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Company;
