const blockInvalidChars = (e, device_id) => {
  if (!e.target.value?.length && ["0"].includes(e.key)) {
    e.preventDefault();
  }

  const valid = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",

    ",",
    "Backspace",
  ].includes(e.key);

  if (!valid) {
    e.preventDefault();
  }

  if (!device_id && e.target.value.length > 6 && e.key !== "Backspace") {
    e.preventDefault();
  }
};

export default blockInvalidChars;
