import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { EmailAddress, Update } from "../../constant";
import { Link } from "react-router-dom";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import { useHistory, useParams, useLocation } from "react-router-dom";
import fileDownload from "js-file-download";
import DataTable from "react-data-table-component";
import { Edit } from "react-feather";
import priceConverter from "../usePriceConverter";

const KlantDetails = (props) => {
  const [gegevensActive, setGegevensActive] = useState(true);
  const [routesActive, setRoutesActive] = useState(false);
  const [facturenActive, setFacturenActive] = useState(false);
  const [exceptionsActive, setExceptionsActive] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [routesData, setRoutesData] = useState([]);
  const [exceptionData, setExceptionData] = useState([]);
  const [voornaam, setVoornaam] = useState();
  const [achternaam, setAchternaam] = useState();
  const [adres, setAdres] = useState();
  const [postcode, setPostcode] = useState();
  const [stad, setStad] = useState();
  const [email, setEmail] = useState();
  const [auto, setAuto] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [adminType, setAdminType] = useState("");
  const history = useHistory();
  const location = useHistory();
  let { search } = useLocation();

  let { id } = useParams();

  useEffect(() => {
    const type = localStorage.getItem("type");

    setAdminType(type);

    if (type) {
      getData(type);
      getInvoices(type);
      getUserCar(type);
      getExceptions(type);
    }
  }, []);

  const TableHeader = ["ID", "Type", "Datum", "Status", "Bedrag", "PDF"];
  const routesTableHeader = [
    "Route ID",
    "Start rit",
    "Startadres",
    "Stopadres",
    "Stop rit",
    "Km-stand start",
    "Km-stand einde",
  ];

  useEffect(() => {
    if (location?.location?.state) {
      setRoutesActive(true);
      setGegevensActive(false);
      setFacturenActive(false);
    }
  }, [location?.location?.state]);

  const exceptionTableColumns = [
    {
      name: "Start uur",
      selector: "start_hour",
      sortable: true,
      center: true,
    },
    {
      name: "Eind uur",
      selector: "end_hour",
      sortable: true,
      center: true,
    },
    {
      name: "Datum",
      selector: "date",
      sortable: true,
      center: true,
    },
  ];

  const invoiceTableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Aan",
      selector: (row) => row?.first_name + " " + row?.last_name,
      sortable: true,
      center: true,
    },
    {
      name: "Bedrag",
      selector: (row) => priceConverter(row?.amount) + " " + "€",
      sortable: true,
      center: true,
    },
    {
      name: "Datum",
      selector: "created_at",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "PDF",
      cell: (row, index, column, id) => {
        return (
          <a
            className="btn btn-primary"
            download={`${row.first_name}_${row.last_name}_invoice_${row.invoice_key}`}
            href={`${API_URL}/invoice/download-invoice-admin/${row.invoice_key}`}
          >
            <i className="fa fa-download" style={{ color: "white" }}></i>
          </a>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      cell: (row, index, column, id) => {
        return row?.is_paid ? (
          <p
            style={{ minWidth: "100px" }}
            className="bg-success py-2 px-2 text-black rounded-lg text-center"
          >
            Betaald
          </p>
        ) : (
          <p
            style={{ minWidth: "100px" }}
            className="bg-danger py-2 px-2 text-black rounded-lg text-center"
            onClick={() => {
              adminType !== "Bedrijf" && setSelectedInvoiceId(row?.id);
              adminType !== "Bedrijf" && setModal(true);
            }}
          >
            Niet Betaald
            {adminType !== "Bedrijf" && (
              <Edit
                className="ml-2"
                style={{ height: "14px", width: "14px" }}
              />
            )}
          </p>
        );
      },
      minWidth: "200px",
      sortable: true,
      center: true,
    },
  ];

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
      "content-disposition": "attachment",
    },
  };

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Soort rit",
      selector: (row) => {
        return row?.site == 1 ? "Zakelijk" : "Particulier";
      },
      sortable: true,
      center: true,
    },
    {
      name: "Start rit",
      selector: "start_date",
      sortable: true,
      center: true,
    },
    {
      name: "Startadres",
      selector: "start_location",
      sortable: true,
      center: true,
    },
    {
      name: "Stopadres",
      selector: "end_location",
      sortable: true,
      center: true,
    },
    {
      name: "Stop rit",
      selector: "end_time",
      sortable: true,
      center: true,
    },
    {
      minWidth: "180px",
      name: "Total Kilometers",
      selector: "total_kilometers",
      sortable: true,
      center: true,
    },

    {
      minWidth: "180px",
      name: "Km-stand start",
      selector: "start_kilometer_stand",
      sortable: true,
      center: true,
    },
    {
      minWidth: "180px",
      name: "Km-stand einde",
      selector: "end_kilometer_stand",
      sortable: true,
      center: true,
    },
  ];

  const { params } = useParams();

  const getUserCar = async (type) => {
    let axiosUrl;

    if (type) {
      if (type === "Bedrijf") {
        axiosUrl = `${API_URL}/car/company-customers/car/${id}`;
      } else {
        axiosUrl = `${API_URL}/car/customers/car/${id}`;
      }
    }

    await axios
      .get(axiosUrl, ApiConfig)
      .then((res) => {
        setAuto(res.data);
        console.log(res.data[0]?.id);
        console.log(res.data[0]);
        getRoutes(res.data[0]?.id);
      })
      .catch((e) => {
        toast.error("Kan gebruiker auto gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    if (location?.location?.state) {
      setRoutesActive(true);
      setGegevensActive(false);
      setFacturenActive(false);
    }
  }, []);

  const approveInvoiceAsPaid = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/invoice/approve-invoice/${selectedInvoiceId}`,
        ApiConfig
      );
      toast.success("Factuur is succesvol goedgekeurd als Betaald.");
      getInvoices(adminType);
      setModal(false);
    } catch (e) {
      toast.error("Factuur kan niet worden goedgekeurd.");
    }
  };

  const updateUser = (e) => {
    //Todo: add validation to form
    e.preventDefault();

    let axiosUrl;

    if (adminType === "Bedrijf") {
      axiosUrl = `${API_URL}/company/modify-customer/${id}`;
    } else {
      axiosUrl = `${API_URL}/admin/modify-customer/${id}`;
    }

    let bodyParameters;

    if (adminType === "Bedrijf") {
      bodyParameters = {
        address: adres,
        city: stad,
        zip_code: postcode,
      };
    } else {
      bodyParameters = {
        first_name: voornaam,
        last_name: achternaam,
        user_name: voornaam + achternaam,
        email: email.toLowerCase(),
        address: adres,
        city: stad,
        zip_code: postcode,
      };
    }

    axios
      .put(axiosUrl, bodyParameters, ApiConfig)
      .then(function (response) {
        getData(adminType);
        toast.success("Gebruiker gegevens zijn geupdate!");
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const getData = async (type) => {
    let axiosUrl;

    if (type === "Bedrijf") {
      axiosUrl = `${API_URL}/company/customer/${id}`;
    } else {
      axiosUrl = `${API_URL}/superadmin/customer/${id}`;
    }

    await axios
      .get(axiosUrl, ApiConfig)
      .then((res) => {
        setVoornaam(res.data[0].first_name);
        setAchternaam(res.data[0].last_name);
        setAdres(res.data[0].address);
        setPostcode(res.data[0].zip_code);
        setStad(res.data[0].city);
        setEmail(res.data[0].email);
      })
      .catch((e) => {
        toast.error("Kan gebruiker gegevens niet ophalen.");
      });
  };

  const getRoutes = async (car_id) => {
    await axios
      .post(
        `${API_URL}/company/customer-routes/${car_id}`,
        { customer_id: id },
        ApiConfig
      )
      .then((res) => {
        setRoutesData(res.data);
      })
      .catch((e) => {
        toast.warning("Kan routen niet ophalen.");
      });
  };

  const getExceptions = async (type) => {
    let axiosUrl;

    if (type === "Bedrijf") {
      axiosUrl = `${API_URL}/company/customer-exceptions/${id}`;
    } else {
      axiosUrl = `${API_URL}/superadmin/customer-exceptions/${id}`;
    }

    await axios
      .get(axiosUrl, ApiConfig)
      .then((res) => {
        setExceptionData(res.data);
      })
      .catch((e) => {
        toast.warning("Kan vrijstellingen niet ophalen.");
      });
  };

  const getInvoices = async (type) => {
    let axiosUrl;
    if (type === "Bedrijf") {
      axiosUrl = `${API_URL}/invoice/customer/invoices/${id}`;
    } else {
      axiosUrl = `${API_URL}/invoice/customer/invoices/${id}`;
    }

    await axios
      .get(axiosUrl, ApiConfig)
      .then((res) => {
        setInvoiceData(res.data);
      })
      .catch((e) => {
        toast.info("Kan facturen niet ophalen.");
      });
  };

  const handleLinkCar = () => {
    history.push(`${process.env.PUBLIC_URL}/voertuigen`);
  };

  const downloadExcelForRoutes = async () => {
    axios
      .post(`${API_URL}/company/send-customer-csv/${id}`, ApiConfig, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        fileDownload(blob, `ritten_excel.xlsx`); // npm " js-file-download" responsible for downloading the file for the user
      })

      .catch((e) => {
        toast.error("Kan ritten excel niet ophalen.");
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Details" />
      <Container fluid={true}>
        <Row>
          <Col className="text-center mb-4" md="12">
            <button
              style={{ minWidth: "120px" }}
              className="btn btn-primary md:mr-0 mr-2 mt-md-0 mt-2"
              type="button"
              onClick={() => {
                setRoutesActive(true);
                setGegevensActive(false);
                setFacturenActive(false);
                setExceptionsActive(false);
              }}
            >
              Ritten
            </button>
            <button
              style={{ minWidth: "120px" }}
              className="btn btn-primary mx-mr-4 mx-2 mt-md-0 mt-2"
              type="button"
              onClick={() => {
                setExceptionsActive(true);
                setRoutesActive(false);
                setGegevensActive(false);
                setFacturenActive(false);
              }}
            >
              Vrijstellingen/verlengingen
            </button>
            <button
              style={{ minWidth: "120px", marginRight: "0.5rem" }}
              className="btn btn-primary ml-2 mt-md-0 mt-2"
              type="button"
              onClick={() => {
                setRoutesActive(false);
                setGegevensActive(true);
                setFacturenActive(false);
                setExceptionsActive(false);
              }}
            >
              Gegevens
            </button>
            <button
              style={{ minWidth: "120px" }}
              className="btn btn-primary mt-md-0 mt-2"
              type="button"
              onClick={() => {
                setRoutesActive(false);
                setGegevensActive(false);
                setFacturenActive(true);
                setExceptionsActive(false);
              }}
            >
              Facturen
            </button>
          </Col>
        </Row>
        <div className="edit-profile">
          <Row>
            <Col lg="12" xl="4">
              <Card className="flex">
                <CardHeader>
                  <h4 className="card-title mb-0">Profiel</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="d-md-flex flex flex-md-column flex-column justify-content-md-between justify-content-between align-items-md-center align-items-center">
                      <div className="col-auto">
                        <Media
                          className="img-70 rounded-circle"
                          alt=""
                          src={require("../../assets/images/user/7.jpg")}
                        />
                      </div>
                      <Col className="mt-md-2 d-md-flex d-flex flex-md-column flex-column justify-content-md-between justify-content-between align-items-md-center align-items-center">
                        <h3 className="mb-1 text-center">
                          {voornaam} {achternaam}
                        </h3>
                        <p className="mb-4">Gebruiker</p>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Col className="d-md-flex d-flex flex-md-column flex-column justify-content-md-between justify-content-between align-items-md-center align-items-center">
                        <h5 className="mb-1">{EmailAddress}</h5>
                        <p className="mb-4"> {email}</p>
                      </Col>
                      <Col className="d-md-flex d-flex flex-md-column flex-column justify-content-md-between justify-content-between align-items-md-center align-items-center">
                        <h5 className="mb-1">Auto</h5>
                        {auto.length === 0 ? (
                          <>
                            <p className="text-center">
                              Deze gebruiker heeft nog geen auto.
                            </p>
                            <button
                              className="btn btn-secondary mt-4"
                              onClick={() => handleLinkCar()}
                            >
                              Voeg een auto toe
                            </button>
                          </>
                        ) : (
                          <>
                            <p className="mt-md-2 mt-2 mb-0 text-underline font-weight-bold">
                              Brand:
                            </p>
                            <span className="text-decoration-none font-weight-normal">
                              {auto[0]?.brand}
                            </span>
                            <p className="mt-md-2 mt-2 mb-0 text-underline font-weight-bold">
                              Type:
                            </p>{" "}
                            <span className="text-decoration-none font-weight-normal">
                              {" "}
                              {auto[0]?.type}
                            </span>
                            <p className="mt-md-2 mt-2 mb-0 text-underline font-weight-bold">
                              Year:
                            </p>{" "}
                            <span className="text-decoration-none font-weight-normal">
                              {" "}
                              {auto[0]?.year}
                            </span>
                            <p className="mt-md-2 mt-2 mb-0 text-underline font-weight-bold">
                              Kenteken:
                            </p>
                            <span className="text-decoration-none font-weight-normal">
                              {auto[0]?.kenteken}
                            </span>
                            {auto[0]?.insurance_policy && (
                              <a
                                className="btn btn-secondary mt-md-4 mt-3"
                                download={`$verzekeringspolis_${auto[0]?.insurance_policy}`}
                                href={`${API_URL}/car/download-insurance-policy/${auto[0]?.insurance_policy}`}
                              >
                                <span>Download huidige verzekeringspolis</span>{" "}
                                {""} {""}
                                <i
                                  className="fa fa-download"
                                  style={{ color: "white" }}
                                ></i>
                              </a>
                            )}
                          </>
                        )}
                      </Col>
                      {auto.length != 0 && (
                        <Col className="d-md-flex d-flex justify-content-md-center justify-content-center align-items-md-center align-items-center">
                          <Link
                            to={{
                              pathname: `/klant/ritten/${auto[0]?.id}`,
                              state: { customer_id: id },
                            }}
                          >
                            <button
                              className="btn btn-secondary mt-md-4 mt-3"
                              type="submit"
                            >
                              Bekijk ritgegevens gebruiker
                            </button>
                          </Link>
                        </Col>
                      )}
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col md="12" xl="8" className={gegevensActive ? null : "hidden"}>
              <Form className="card" onSubmit={(e) => updateUser(e)}>
                <CardHeader>
                  <h4 className="card-title mb-0">Gegevens</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6" md="6" className="mt-md-0 mt-2">
                      <FormGroup>
                        <Label className="form-label">Voornaam</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={voornaam}
                          disabled={adminType === "Bedrijf" && true}
                          onChange={(e) => setVoornaam(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mt-md-0 mt-2">
                      <FormGroup>
                        <Label className="form-label">Achternaam</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={achternaam}
                          disabled={adminType === "Bedrijf" && true}
                          onChange={(e) => setAchternaam(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          required
                          value={email}
                          disabled={adminType === "Bedrijf" && true}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Woonadres</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={adres}
                          onChange={(e) => setAdres(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Plaats</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={stad}
                          onChange={(e) => setStad(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Postcode</Label>
                        <Input
                          className="form-control"
                          placeholder="e.g. 1234 EP"
                          required
                          value={postcode}
                          onChange={(e) => setPostcode(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="md:text-right text-center">
                  <button className="btn btn-primary" type="submit">
                    Update gegevens
                  </button>
                </CardFooter>
              </Form>
            </Col>
            <Col className={routesActive ? null : "hidden"} md="12" xl="8">
              <Card>
                <CardHeader className="d-flex flex-md-row flex-column align-items-center justify-content-between">
                  <h4 className="card-title mb-0">Ritten</h4>

                  <a
                    onClick={downloadExcelForRoutes}
                    style={{ cursor: "pointer" }}
                    className="card-title mb-0"
                  >
                    Download Excel met alle ritten
                  </a>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={routesData}
                    columns={tableColumns}
                    empty
                    striped={true}
                    center={true}
                    pagination={true}
                    pointerOnHover={true}
                    persistTableHead
                    noDataComponent={
                      <p style={{ padding: 20 }}>
                        Deze gebruiker heeft nog geen ritten.
                      </p>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="12" xl="8" className={facturenActive ? null : "hidden"}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Facturen</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="#javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="#javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={invoiceData}
                    columns={invoiceTableColumns}
                    empty
                    striped={true}
                    center={true}
                    pagination={true}
                    pointerOnHover={true}
                    persistTableHead
                    noDataComponent={
                      <p style={{ padding: 20 }}>
                        Deze gebruiker heeft nog geen facturen.
                      </p>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="12" xl="8" className={exceptionsActive ? null : "hidden"}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">
                    Vrijstellingen/verlengingen
                  </h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="#javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="#javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <h4 className="card-title mb-0">Vrijstellingen</h4>
                  <DataTable
                    data={exceptionData?.exception}
                    columns={exceptionTableColumns}
                    empty
                    striped={true}
                    center={true}
                    pagination={true}
                    pointerOnHover={true}
                    persistTableHead
                    noDataComponent={
                      <p style={{ padding: 20 }}>
                        Deze gebruiker heeft nog geen vrijstellingen toegevoegd.
                      </p>
                    }
                  />
                  <h4 className="card-title mb-0">Verlengingen</h4>
                  <DataTable
                    data={exceptionData?.extension}
                    columns={exceptionTableColumns}
                    empty
                    striped={true}
                    center={true}
                    pagination={true}
                    pointerOnHover={true}
                    persistTableHead
                    noDataComponent={
                      <p style={{ padding: 20 }}>
                        Deze gebruiker heeft nog geen verlengingen toegevoegd.
                      </p>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          Wilt u de status van deze factuur wijzigen?
        </ModalHeader>
        <ModalBody>
          U kunt de status van uw factuur als betaald wijzigen door het
          goedkeuren van deze factuur.
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center align-items-center">
          <Button color="success" onClick={() => approveInvoiceAsPaid()}>
            Goedkeuren als betaald
          </Button>{" "}
          <Button color="light" onClick={() => setModal(!modal)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default KlantDetails;
