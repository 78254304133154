import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";

const Teksten = (props) => {
  const [data, setData] = useState([]);
  const history = useHistory();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const getAllTemplates = async () => {
    try {
      const res = await axios.get(`${API_URL}/template/templates`, ApiConfig);

      setData(res.data);
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const handleClick = (type) => {
    history.push(
      `${process.env.PUBLIC_URL}/communicatie/tekstendetail/${type}`
    );
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Communicatie" title="Teksten" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Teksten</h5>
                <span>
                  {"Beheer email teksten, juridische teksten & meer."}
                </span>
              </CardHeader>
              <CardBody>
                <Row>
                  {data
                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((item) => (
                      <Col key={item?.id} sm="12" xl="6">
                        <Card>
                          <CardHeader>
                            <h5>
                              {item?.title
                                .replace("MS4U", "Moving 4Ward")
                                .replace("GVR", "M4 Mobility")}
                            </h5>
                          </CardHeader>
                          <CardFooter
                            className="bg-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(`${item.type}`)}
                          >
                            <h6 className="mb-0 text-xl-left text-center">
                              Bekijken
                            </h6>
                          </CardFooter>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Teksten;
