import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import myEventsList from './events';
import { useParams, useHistory } from 'react-router-dom';
import { API_URL } from '../../constant';
import axios from 'axios';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from 'reactstrap';
const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

toast.configure();
const Ritten = () => {
  let { id } = useParams();
  const history = useHistory();
  const customer_id = history?.location?.state?.customer_id || null;

  const [routesList, setRoutesList] = useState([]);
  const [name, setName] = useState();

  const [selectedTrip, setSelectedTrip] = useState([]);
  const [detailedTripModal, setDetailedTripModal] = useState();
  console.log(selectedTrip);
  const tableColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      center: true,
    },
    {
      name: 'Soort rit',
      selector: (row) => {
        return row?.site == 1 ? 'Zakelijk' : 'Particulier';
      },
      sortable: true,
      center: true,
    },
    {
      name: 'Start rit',
      selector: 'start_date',
      sortable: true,
      center: true,
    },

    {
      name: 'Startadres',
      selector: 'start_location',
      sortable: true,
      center: true,
    },
    {
      name: 'Stopadres',
      selector: 'end_location',
      sortable: true,
      center: true,
    },
    {
      name: 'Stop rit',
      selector: 'end_time',
      sortable: true,
      center: true,
    },
    {
      name: 'Total Kilometers',
      selector: 'total_kilometers',
      sortable: true,
      center: true,
    },
    {
      minWidth: '180px',
      name: 'Km-stand start',
      selector: 'start_kilometer_stand',
      sortable: true,
      center: true,
    },
    {
      minWidth: '180px',
      name: 'Km-stand einde',
      selector: 'end_kilometer_stand',
      sortable: true,
      center: true,
    },
  ];

  const getRoutes = async () => {
    const ApiConfig = {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    };

    try {
      console.log(customer_id);
      const routes = await axios.post(
        `${API_URL}/company/customer-routes/${id}`,
        { customer_id },
        ApiConfig
      );
      setName(routes?.data[0]?.name);

      const events = routes?.data?.map(
        (route) =>
          (route = {
            title:
              'Van ' + route.start_location + ' naar ' + route.end_location,
            start: new Date(route.start_date),
            end: new Date(route.end_time),
            resource: route,
          })
      );

      setRoutesList(events);
    } catch (e) {
      toast.error(e?.response?.data?.msg);
    }
  };

  const onEventClick = (event) => {
    console.log(event);
    setDetailedTripModal(true);
    setSelectedTrip([event]);
  };

  const CustomEvent = (event) => {
    return (
      <div onClick={() => onEventClick(event)}>
        <p>
          <b>{event.title}</b>
        </p>
        <div className='mb-3'>
          <span>
            <b>Start Kilometerstand:</b>{' '}
            {event.event.resource.start_kilometer_stand}
          </span>
          <span>
            <b> - Einde Kilometerstand:</b>{' '}
            {event.event.resource.end_kilometer_stand}
          </span>
          <span>
            <b>Huidige kilometerstand:</b>{' '}
            {event.event.resource.total_kilometers}
          </span>
        </div>
        <button
          style={{
            backgroundColor: '#2563EB',
            color: 'white',
            fontFamily: 'inherit',
            border: 'none',
            padding: '5px',
            borderRadius: '5px',
          }}
          type='button'
          className='mb-1'
        >
          <b>Meer details over de route</b>
        </button>
      </div>
    );
  };

  useEffect(() => {
    getRoutes();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='Gebruiker' title='Ritten' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <h5>Bekijk de ritten van {name}</h5>
              </CardHeader>
              <CardBody>
                <div style={{ minHeight: 500 }}>
                  <Calendar
                    localizer={localizer}
                    scrollToTime={new Date()}
                    defaultDate={new Date()}
                    views={allViews}
                    events={routesList}
                    components={{
                      agenda: { event: CustomEvent },
                    }}
                    eventOverlap
                    dragRevertDuration={500}
                    dragScroll
                    droppable={true}
                    showMultiDayTimes
                    step={60}
                    startAccessor='start'
                    endAccessor='end'
                    onSelectEvent={(event) => onEventClick(event?.resource)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size='xl'
        isOpen={detailedTripModal}
        toggle={() => setDetailedTripModal(!detailedTripModal)}
      >
        <ModalHeader toggle={() => setDetailedTripModal(!detailedTripModal)}>
          Details van de gekozen route
        </ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          <DataTable
            data={selectedTrip}
            columns={tableColumns}
            empty
            striped={true}
            center={true}
            pagination={true}
            pointerOnHover={true}
            persistTableHead
            noDataComponent={
              <p style={{ padding: 20 }}>
                Deze gebruiker heeft nog geen ritten.
              </p>
            }
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Ritten;
