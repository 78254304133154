const now = new Date();

export default [
  {
    id: 6,
    title: "Mert",
    text: "New Car",
    start: new Date(2021, 3, 12, 10, 30, 0, 0),
    end: new Date(2021, 3, 12, 12, 30, 0, 0),
  },
  {
    id: 8,
    title: "Van Amsterdam naar Rotterdam",
    start: new Date(2019, 3, 12, 14, 0, 0, 0),
    end: new Date(2019, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: "Van Amsterdam naar Rotterdam",
    start: new Date(2019, 3, 12, 17, 0, 0, 0),
    end: new Date(2019, 3, 12, 17, 30, 0, 0),
  },
  {
    id: 10,
    title: "Van Amsterdam naar Rotterdam",
    start: new Date(2019, 3, 12, 20, 0, 0, 0),
    end: new Date(2019, 3, 12, 21, 0, 0, 0),
  },
];
