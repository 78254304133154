import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import blockInvalidChars from "../../hooks/blockInvalidChars";
import { Trash2 } from "react-feather";

const MockupAutos = (props) => {
  const [brand, setBrand] = useState();
  const [type, setType] = useState();
  const [year, setYear] = useState();
  const [modal, setModal] = useState(false);
  const [carModal, setCarModal] = useState(false);
  const [data, setData] = useState([]);

  const [deleteCarId, setDeleteCarId] = useState(null);
  const [adminType, setAdminType] = useState("");

  const [isEditMode, setIsEditMode] = useState(false);
  const [editCarId, setEditCarId] = useState(null);

  const history = useHistory();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const handleRowClicked = (id) => {
    getCarDetails(id);
    setEditCarId(id);
    setIsEditMode(true);
    setCarModal(true);
  };

  const clearFields = () => {
    setBrand();
    setType();
    setYear();
  };

  const handleDelete = async () => {
    await axios
      .delete(`${API_URL}/car/mockup-car-delete/${deleteCarId}`, ApiConfig)
      .then((res) => {
        getData();
        toast.success("Auto is verwijderd.");
        setModal(false);
      })
      .catch((e) => {
        toast.error("Kan auto niet verwijderen.");
      });
  };

  const getCarDetails = async (id) => {
    try {
      const res = await axios.get(`${API_URL}/car/mockup-car/${id}`, ApiConfig);

      setBrand(res?.data[0].brand);
      setType(res?.data[0].type);
      setYear(res?.data[0].year);
    } catch (e) {
      toast.error(e?.response?.data?.msg);
    }
  };

  const createCarOnSubmit = async (e) => {
    e.preventDefault();

    const adminId = await localStorage.getItem("id");

    const bodyParameters = {
      brand,
      type,
      year: year,
    };

    await axios
      .post(`${API_URL}/car/mockup-car-add`, bodyParameters, ApiConfig)
      .then(function (response) {
        toast.success(response.data.msg);
        getData();
        setCarModal(false);
        clearFields();
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const editCarOnSubmit = async (e) => {
    e.preventDefault();

    const adminId = await localStorage.getItem("id");

    const bodyParameters = {
      brand,
      type,
      year: year,
    };

    await axios
      .put(
        `${API_URL}/car/mockup-car-edit/${editCarId}`,
        bodyParameters,
        ApiConfig
      )
      .then(function (response) {
        toast.success(response.data.msg);
        getData();
        setCarModal(false);
        clearFields();
        setEditCarId(null);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/car/mockup-cars`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    getData();
    const type = localStorage.getItem("type");
    setAdminType(type);
  }, []);

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Merk",
      selector: "brand",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Jaar",
      selector: "year",
      sortable: true,
      center: true,
    },
    {
      name: "",
      minWidth: "120px",
      cell: (row, index, column, id) => {
        return (
          <div className="flex justify-center items-center ">
            <button
              type="button"
              onClick={() => {
                setDeleteCarId(row?.id);
                setModal(true);
              }}
              className="bg-danger py-2 px-2 my-2 h-full  text-white rounded-lg text-center border-0"
            >
              Verwijderen
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Voertuigen" title="Voertuigen" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="text-md-left ">
                <span>Beheer hier je wagenpark</span>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className={"btn btn-primary px-md-4 px-2 ml-md-0 mr-2"}
                    onClick={() => {
                      clearFields();
                      setIsEditMode(false);
                      setCarModal(true);
                    }}
                  >
                    Mockup auto toevoegen
                  </button>
                </div>

                <DataTable
                  data={data}
                  columns={tableColumns}
                  empty
                  striped={true}
                  center={true}
                  pagination={true}
                  pointerOnHover={true}
                  persistTableHead
                  onRowClicked={(e) => handleRowClicked(e.id)}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen mockup auto met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={carModal} toggle={() => setCarModal(false)} size="lg">
          <ModalHeader toggle={() => setCarModal(false)}>
            {isEditMode ? "Mockup auto bewerken" : "Mockup auto toevoegen"}
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <Row>
              <Form
                onSubmit={(e) =>
                  isEditMode ? editCarOnSubmit(e) : createCarOnSubmit(e)
                }
              >
                <CardHeader>
                  <h4 className="card-title mb-0 text-center">
                    {isEditMode
                      ? "Mockup auto bewerken"
                      : "Mockup auto toevoegen"}
                  </h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6" md="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Merk</Label>
                        <Input
                          className="form-control text-center"
                          type="text"
                          required
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Type</Label>
                        <Input
                          className="form-control text-center"
                          type="text"
                          required
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Jaar</Label>
                        <Input
                          className="form-control text-center"
                          type="number"
                          required
                          min={0}
                          value={year}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          onChange={(e) => setYear(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-center">
                  <button className="btn btn-primary" type="submit">
                    {isEditMode
                      ? "Mockup auto bewerken"
                      : "Mockup auto aanmaken"}
                  </button>
                </CardFooter>
              </Form>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}>
            Weet je zeker dat je deze mockup auto wilt verwijderen?
          </ModalHeader>
          <ModalBody>
            Als je de auto verwijderd zal de gerelateerde gebruiker ook geen
            auto meer hebben. Deze kan je wel een nieuwe auto weer toewijzen.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => handleDelete()}>
              Verwijderen
            </Button>{" "}
            <Button color="grey" onClick={() => setModal(!modal)}>
              Annuleren
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default MockupAutos;
