import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { tableData } from "../../data/dummyTableData";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  Input,
  Label,
  Button,
  CardFooter,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constant";
import Breadcrumb from "../../layout/breadcrumb";
import { useHistory } from "react-router-dom";

export const ApiConfig = {
  headers: {
    "x-auth-token": localStorage.getItem("token"),
  },
};

const AutoLinken = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const history = useHistory();

  const tableColumns = ["ID", "Merk", "Type", "Jaar"];

  /* 
  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Merk",
      selector: "brand",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Jaar",
      selector: "year",
      sortable: true,
      center: true,
    },
  ];*/

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows[0]);
  }, []);

  const getData = async () => {
    await axios
      .get(`${API_URL}/car/available-cars`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  const handleLink = async (id) => {
    const bodyParameters = {
      customer_id: localStorage.getItem("userid"),
      car_id: id,
    };

    await axios
      .post(`${API_URL}/car/add-customer-to-car`, bodyParameters, ApiConfig)
      .then(function (response) {
        toast.success("Auto is gelinkt aan gebruiker!");
        history.push(`${process.env.PUBLIC_URL}/klant`);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Auto Linken" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        {tableColumns.map((items, i) => (
                          <th key={i}>{items}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((items, i) => (
                        <tr key={i}>
                          <td>{items?.id}</td>
                          <td>{items?.brand}</td>
                          <td>{items?.type}</td>
                          <td>{items?.year}</td>
                          <td className="text-right">
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => handleLink(items?.id)}
                            >
                              Link
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {data.length === 0 && (
                    <p>
                      Er zijn geen gebruikers die goedgekeurd moeten worden.
                    </p>
                  )}
                </div>
                {/*
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  selectedRows={selectedRows}
                  selectableRowsHighlight
                  contextMessage={{
                    singular: "auto",
                    plural: "autos",
                    message: "geselecteerd",
                  }}
                  selectableRowsSingle={true}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen auto's zonder klant.
                    </p>
                  }
                />*/}
              </CardBody>
              <CardFooter>
                {selectedRows?.length !== 0 || selectedRows === undefined ? (
                  <Button onClick={() => handleLink()}>
                    Auto linken aan gebruiker
                  </Button>
                ) : null}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AutoLinken;
