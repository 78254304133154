import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import SimpleMDE from "react-simplemde-editor";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const TekstenDetail = (props) => {
  const [text, setText] = useState("");
  const [email, setEmail] = useState(false);

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  let { type } = useParams();

  const getData = async () => {
    try {
      if (type) {
        const res = await axios.get(
          `${API_URL}/template/template-text/${type}`,
          ApiConfig
        );

        !res.data ? setText() : setText(res.data.toString());
      }
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const changeText = async () => {
    try {
      const bodyParams = {
        text,
      };

      if (type) {
        const res = await axios.post(
          `${API_URL}/template/change-template-text/${type}`,
          bodyParams,
          ApiConfig
        );

        getData();
        toast.success("Tekst is succesvol gewijzigd.");
      }
    } catch (e) {
      toast.error("Niet gelukt.");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeEditor = (value) => {
    setText(value);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Communicatie"
        title={localStorage.getItem("emailType")}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h5>{type}</h5>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => changeText()}
                  >
                    Opslaan
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <SimpleMDE
                  id="editor_container"
                  onChange={onChangeEditor}
                  options={{
                    autofocus: true,
                    spellChecker: false,
                  }}
                  value={text}
                />

                {email ? (
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <table
                            style={{
                              backgroundColor: "#f6f7fb",
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <table
                                    style={{
                                      width: "650px",
                                      margin: "0 auto",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td>
                                          <img
                                            src={require("../../assets/images/logo/logo.png")}
                                            alt=""
                                            width={100}
                                          />
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "right",
                                            color: "#999",
                                          }}
                                        >
                                          <span>{type}</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    style={{
                                      width: "650px",
                                      margin: "0 auto",
                                      backgroundColor: "#fff",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: "30px" }}>
                                          <p>
                                            <ReactMarkdown
                                              source={text}
                                              className="markup-text"
                                            />
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    style={{
                                      width: "650px",
                                      margin: "0 auto",
                                      marginTop: "30px",
                                    }}
                                  >
                                    <tbody>
                                      <tr style={{ textAlign: "center" }}>
                                        <td>
                                          <p
                                            style={{
                                              color: "#999",
                                              marginBottom: 0,
                                            }}
                                          >
                                            Adresgegevens
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TekstenDetail;
