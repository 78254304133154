import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { tableData } from '../../data/dummyTableData';
import { useForm } from 'react-hook-form';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  Input,
  Label,
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'reactstrap';
import StepZilla from 'react-stepzilla';
import axios from 'axios';
import { API_URL } from '../../constant';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
export const ApiConfig = {
  headers: {
    'x-auth-token': localStorage.getItem('token'),
  },
};

export const ApiConfig1 = {
  headers: {
    'x-auth-token': localStorage.getItem('token'),
    'Content-Type': 'multipart/form-data',
  },
};

export const NieuwFactuur = () => {
  const [data, setData] = useState(tableData);
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modal, setModal] = useState(false);
  const [allData, setAllData] = useState([]);
  const [prijs, setPrijs] = useState('');
  const [beschrijving, setBeschrijving] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(1);
  const [nextStep, setNextStep] = useState(false);
  const [vatOn, setVatOn] = useState(1);

  const handleVatOnModalOpen = () => {
    setModal(true);
    setVatOn(1);
  };
  const ApiConfig = {
    headers: {
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  const handleClearRows = () => {
    setToggleCleared(!toggleCleared);
  };

  useEffect(() => {}, [nextStep === true]);

  const tableColumns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      center: true,
    },
    {
      name: 'Voornaam',
      selector: 'first_name',
      sortable: true,
      center: true,
    },
    {
      name: 'Achternaam',
      selector: 'last_name',
      sortable: true,
      center: true,
    },
    {
      name: 'Site',
      selector: (row) => (row.site == 1 ? 'Z' : 'P'),
      sortable: true,
      center: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      center: true,
    },
  ];

  const tableColumnsForInvoiceDownload = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      center: true,
    },
    {
      name: 'Voornaam',
      selector: 'first_name',
      sortable: true,
      center: true,
    },
    {
      name: 'Achternaam',
      selector: 'last_name',
      sortable: true,
      center: true,
    },
    {
      name: 'Site',
      selector: (row) => (row.site == 1 ? 'Z' : 'P'),
      sortable: true,
      center: true,
    },
    {
      name: 'Factuur PDF',
      cell: (row, index, column, id) => {
        return (
          <a
            download={`${row.first_name}_${row.last_name}_invoice_${row.invoice_key}`}
            href={`${API_URL}/invoice/download-invoice-admin/${row.invoice_key}`}
          >
            Download
          </a>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  const excludeColumns = [
    'user_name',
    'id',
    'password',
    'address',
    'zip_code',
    'city',
    'forgot_token',
    'activation_key',
    'is_activated',
    'is_approved',
    'activation_date',
  ];

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const search = (value) => {
    filterData(value);
  };

  const typeConvert = () => {
    if (type == 1) {
      return 'Schade';
    } else if (type == 2) {
      return 'Bekeuring';
    } else if (type == 3) {
      return 'Overig';
    } else {
      toast.error('Geef een juist type aan.');
      return false;
    }
  };

  const createAndSendFacturen = async () => {
    setLoading(true);
    const customer_ids = selectedRows?.map((selectedRow) => {
      return selectedRow.id;
    });
    const bodyParams = {
      customer_ids,
      amount: parseFloat(prijs),
      type: await typeConvert(),
      vat: vatOn == 2 ? true : false,
    };

    if (beschrijving && beschrijving?.length > 50) {
      toast.warning(
        'De tekstlengte moet 50 of minder tekens zijn voor de beschrijving'
      );
      setLoading(false);
      return;
    }

    if (beschrijving) {
      bodyParams['description'] = beschrijving;
    }

    try {
      const res = await axios
        .post(`${API_URL}/invoice/create-invoice`, bodyParams, ApiConfig)
        .then((response) => {
          setLoading(false);
          toast.success(
            'Facturen worden succesvol naar klanten verstuurd als PDF. U kunt ze ook downloaden als PDF.'
          );

          setInvoiceData(response.data);
          setModal(false);
          setNextStep(true);
          handleClearRows();
        });
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data.msg);
    }
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setData(allData);
    else {
      const filteredData = allData.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns?.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/superadmin/customers`, ApiConfig)
      .then((res) => {
        setData(res.data);
        setAllData(res.data);
      })
      .catch((e) => {
        toast.error('Kan gebruiker gegevens niet ophalen.');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent='Facturen' title='Nieuw Factuur' />
      <Container className={nextStep && 'hidden'} fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader
                className='bg-light-primary'
                style={{ padding: '15px' }}
              >
                <Form className='theme-form'>
                  <InputGroup className='m-0'>
                    <Input
                      className='form-control-plaintext px-2'
                      type='search'
                      placeholder='Zoeken op naam of email ..'
                      onChange={(e) => search(e.target.value)}
                    />
                  </InputGroup>
                </Form>
              </CardHeader>
              <h4 className='ml-5 mt-4 px-md-3 px-2'>
                Kies een gebruiker/gebruikers om een factuur te maken.
              </h4>
              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  selectedRows={selectedRows}
                  selectableRowsHighlight
                  selectableRows
                  contextMessage={{
                    singular: 'gebruiker',
                    plural: 'gebruikers',
                    message: 'geselecteerd',
                  }}
                  pagination={true}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen gebruikers met deze specificatie.
                    </p>
                  }
                />
                <div className='d-flex justify-content-center align-items-center'>
                  <Button
                    color='primary'
                    style={{ marginTop: 20 }}
                    type='submit'
                    onClick={() =>
                      selectedRows.length > 0
                        ? handleVatOnModalOpen()
                        : toast.error(
                            'U moet ten minste 1 gebruiker selecteren om verder te gaan met het aanmaken van een factuur.'
                          )
                    }
                  >
                    Verder
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className={!nextStep && 'hidden'} fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader
                className='bg-light-primary'
                style={{ padding: '15px' }}
              >
                <Form className='theme-form'>
                  <InputGroup className='m-0'>
                    <Input
                      className='form-control-plaintext px-2'
                      type='search'
                      placeholder='Zoeken op voornaam, achternaam of email ..'
                      onChange={(e) => search(e.target.value)}
                    />
                  </InputGroup>
                </Form>
              </CardHeader>
              <h4 className='ml-5 mt-4 px-md-3 px-2'>
                Facturen zijn succesvol aangemaakt en verzonden naar klanten.
              </h4>
              <CardBody>
                <DataTable
                  data={invoiceData}
                  columns={tableColumnsForInvoiceDownload}
                  striped={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  pagination={true}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen gebruikers met deze specificatie.
                    </p>
                  }
                />
                <div className='d-flex justify-content-center align-items-center'>
                  <Button
                    color='primary'
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      setNextStep(!nextStep);
                    }}
                  >
                    Ga terug naar het aanmaken van nieuwe facturen/facturen.
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(false)} size='lg'>
        <ModalHeader toggle={() => setModal(false)}>
          Nieuwe factuur/facturen aanmaken
        </ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor='exampleFormControlInput1'>Bedrag</Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>€</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className='form-control'
                      type='number'
                      placeholder='e.g. 250'
                      required
                      min='0'
                      name='amount_input'
                      value={prijs}
                      onChange={(e) => setPrijs(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-md-2 mt-2'>
              <Col>
                <FormGroup>
                  <Label htmlFor='exampleFormControlSelect3'>Type</Label>
                  <Input
                    type='select'
                    name='type_select'
                    className='form-control digits'
                    multiple=''
                    required
                    defaultValue={1}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={1}>{'Schade'}</option>
                    <option value={2}>{'Bekeuring'}</option>
                    <option value={3}>{'Overig'}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-md-2 mt-2'>
              <Col>
                <FormGroup className='mb-0'>
                  <Label>Beschrijving</Label>
                  <Input
                    type='textarea'
                    value={beschrijving}
                    onChange={(e) => setBeschrijving(e.target.value)}
                    className='form-control'
                    rows='3'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='mt-md-2 mt-2'>
              <Col className='d-flex justify-content-center align-items-center'>
                <FormGroup tag='fieldset' style={{ maxWidth: 350 }}>
                  <FormGroup check>
                    <Input
                      name='radio1'
                      type='radio'
                      value={1}
                      onChange={() => setVatOn(1)}
                      defaultChecked
                    />{' '}
                    <Label check>BTW niet inbegrepen {'=>'} BTW 0%</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name='radio1'
                      type='radio'
                      value={2}
                      onChange={() => setVatOn(2)}
                    />{' '}
                    <Label check>
                      Prijs inc BTW (%21) {'=>'} 21% BTW wordt toegevoegd
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <Button
              color='primary'
              disabled={loading && true}
              style={{ marginTop: 20 }}
              onClick={() => {
                type && prijs
                  ? createAndSendFacturen()
                  : toast.error('U moet Bedrag en Type specificeren.');
              }}
            >
              {loading
                ? 'Factuur/facturen worden aangemaakt en verzonden....'
                : 'Factuur/Facturen aanmaken'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default NieuwFactuur;
