import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { SampleCard } from "../../constant";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const LeasemaatschappijDetail = (props) => {
  const [info, setInfo] = useState([]);
  const [cars, setCars] = useState([]);
  const [customers, setCustomers] = useState([]);

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const getInfo = async () => {
    await axios
      .get(
        `${API_URL}/superadmin/leasecompany/info/${localStorage.getItem(
          "lease"
        )}`,
        ApiConfig
      )
      .then((res) => {
        setInfo(res.data[0]);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  const getCars = async () => {
    let axiosUrl;

    if (localStorage.getItem("type") === "Super Admin") {
      axiosUrl = `${API_URL}/car/leasecompany/cars/${localStorage.getItem(
        "lease"
      )}`;
    } else {
      axiosUrl = `${API_URL}/car/leasecompany/cars`;
    }

    await axios
      .get(axiosUrl, ApiConfig)
      .then((res) => {
        setCars(res.data);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  const getCustomers = async () => {
    await axios
      .get(
        `${API_URL}/superadmin/leasecompany/customers/${localStorage.getItem(
          "lease"
        )}`,
        ApiConfig
      )
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    //getCustomers();
    getInfo();
    getCars();
  }, []);

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Merk",
      selector: "brand",
      sortable: true,
      center: true,
    },
    {
      name: "Toegewezen Bedrijf",
      selector: (row) => (row.company_name ? row.company_name : "-"),
      sortable: true,
      center: true,
    },
    {
      name: "Gekoppelde Gebruiker",
      selector: (row) => (row.customer_name ? row.customer_name : "-"),
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },

    {
      name: "Jaar",
      selector: "year",
      sortable: true,
      center: true,
    },

    {
      name: "Status",
      selector: (row) => (row.customer_id ? "Toegewezen" : "Niet toegewezen"),
      sortable: true,
      center: true,
    },
  ];
  console.log(cars);
  return (
    <Fragment>
      <Breadcrumb parent="Voertuigen" title="Partner" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{info?.user_name}</h5>
                <span>{info?.email}</span>
              </CardHeader>
              <CardBody>
                <h2 style={{ marginTop: 30 }}>Gelinkte auto's</h2>

                <DataTable
                  data={cars}
                  columns={tableColumns}
                  empty
                  striped={true}
                  center={true}
                  pagination={true}
                  pointerOnHover={true}
                  persistTableHead
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Deze partner heeft nog geen auto's.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LeasemaatschappijDetail;
