import React, { Fragment, useEffect, useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  FormText,
  Table,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import blockInvalidChars from "../../hooks/blockInvalidChars";
import { Trash2 } from "react-feather";
import priceConverter from "../usePriceConverter";
import NumberFormat from "react-number-format";

const Voertuigen = (props) => {
  const [kenteken, setKenteken] = useState();
  const [brand, setBrand] = useState();
  const [type, setType] = useState();
  const [year, setYear] = useState();
  const [kilometerStand, setKilometerStand] = useState();
  const [modal, setModal] = useState(false);
  const [carModal, setCarModal] = useState(false);
  const [data, setData] = useState([]);
  const [linkModal, setLinkModal] = useState(false);
  const [gebruikerData, setGebruikerData] = useState([]);
  const [bedrijvenData, setBedrijvenData] = useState([]);
  const [linkCarId, setLinkCarId] = useState(null);
  const [linkGebruikerId, setLinkGebruikerId] = useState(null);
  const [deleteCarId, setDeleteCarId] = useState(null);
  const [adminType, setAdminType] = useState("");
  const [bedrijfLinkModal, setBedrijfLinkModal] = useState(false);
  const [assignedCarData, setAssignedCarData] = useState([]);
  const [kleur, setKleur] = useState();
  const [uitvoering, setUitvoering] = useState();
  const [brandstof, setBrandstof] = useState();
  const [brandstofExtra, setBrandstofExtra] = useState(2);
  const [catalogusWaarde, setCatalogusWaarde] = useState();
  const [additionalNote, setAdditionalNote] = useState();
  const [leasePrice, setLeasePrice] = useState();
  const [exceedingKmPrice, setExceedingKmPrice] = useState();

  const [availableDays, setAvailableDays] = useState([]);
  const [availableHours, setAvailableHours] = useState([]);

  const [availableStartDay, setAvailableStartDay] = useState(1);
  const [availableEndDay, setAvailableEndDay] = useState(5);
  const [availableStartHour, setAvailableStartHour] = useState(1);
  const [availableEndHour, setAvailableEndHour] = useState(10);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editCarId, setEditCarId] = useState(null);

  const [deviceId, setDeviceId] = useState(null);
  const [assetId, setAssetId] = useState(null);

  const [removeDeviceModal, setRemoveDeviceModal] = useState(false);

  const [totalKilometers, setTotalKilometers] = useState();
  const [includedMileagePrivate, setIncludedMileagePrivate] = useState(null);
  const [includedMileageBusiness, setIncludedMileageBusiness] = useState(null);
  const [totalPriceSaved, setTotalPriceSaved] = useState(null);
  const [monthlyPrice, setMonthlyPrice] = useState(null);
  const [kentekenUnselectedBrandstof, setKentekenUnselectedBrandstof] =
    useState("");

  const [tripInCloudsKilometers, setTripInCloudsKilometers] = useState(null);

  const [vacationOpen, setVacationOpen] = useState(0);
  const [vacations, setVacations] = useState([]);
  const [vacationStartDate, setVacationStartDate] = useState();
  const [vacationEndDate, setVacationEndDate] = useState();

  const [damageReportModal, setDamageReportModal] = useState(false);
  const [damageReportStatus, setDamageReportStatus] = useState(1);
  const [damageReports, setDamageReports] = useState([]);
  const [selectedDamageReport, setSelectedDamageReport] = useState(null);

  const vacationRemoveHandler = (index) => {
    let newVacations = [...vacations];

    if (newVacations[index].id) {
      newVacations[index].deleted = true;
    } else {
      if (index > -1) {
        newVacations.splice(index, 1);
      }
    }

    setVacations(newVacations);
    toast.success(
      "Vakantieperiode is succesvol verwijderd. Sla uw wijzigingen op."
    );
    setVacationOpen(0);
  };

  const vacationAddHandler = (start_date, end_date) => {
    if (vacations?.filter((item) => !item.deleted).length >= 3) {
      toast.warning(
        "U heeft uw limiet bereikt voor het creëren van vakantieperiodes (3)."
      );
      return;
    }

    if (!start_date) {
      toast.warning("Geef een begindatum voor de vakantie");
      return;
    }

    if (!end_date) {
      toast.warning("Geef een einddatum voor vakantie");
      return;
    }

    if (start_date && end_date && new Date(end_date) < new Date(start_date)) {
      toast.warning(
        "De begindatum van de vakantieperiode kan niet kleiner zijn dan de einddatum."
      );
      return;
    }

    setVacations((prev) => [...prev, { start_date, end_date }]);
    toast.success(
      "Vakantieperiode is met succes toegevoegd. Sla uw wijzigingen op."
    );
    setVacationStartDate();
    setVacationEndDate();
    setVacationOpen(0);
  };

  const history = useHistory();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const brandstofRef = useRef();

  const types = [
    {
      id: 0,
      name: "-",
      label: "-",
    },
    {
      id: 1,
      name: "Benzine",
      label: "Benzine",
    },
    {
      id: 2,
      name: "Diesel",
      label: "Diesel",
    },
    {
      id: 3,
      name: "Elektrisch",
      label: "Elektrisch",
    },
    {
      id: 4,
      name: "Elektrisch + Benzine",
      label: "Hybride Benzine",
    },
    {
      id: 5,
      name: "Elektrisch + Diesel",
      label: "Hybride Diesel",
    },
  ];

  const converterBrandstof = (name) => {
    if (name == "Benzine") {
      return 1;
    } else if (name == "Diesel") {
      return 2;
    } else if (name == "Elektrisch" || name === "Elektriciteit") {
      return 3;
    } else if (name == "Elektrisch + Benzine") {
      return 4;
    } else if (name == "Elektrisch + Diesel") {
      return 5;
    } else {
      return 0;
    }
  };

  const converterBrandstofForValue = (value) => {
    if (value == 1) {
      return "Benzine";
    } else if (value == 2) {
      return "Diesel";
    } else if (value == 3) {
      return "Elektrisch";
    } else if (value == 4) {
      return "Elektrisch + Benzine";
    } else if (value == 5) {
      return "Elektrisch + Diesel";
    } else {
      return null;
    }
  };

  //const [selectedRange, setSelectedRange] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const optionsForDate = [
    { id: 1, name: "1 jaar" },
    { id: 2, name: "2 jaar" },
    { id: 3, name: "3 jaar" },
  ];

  const getAvailableHours = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/admin/get-available-hours`,
        ApiConfig
      );

      setAvailableHours(res.data);
      setAvailableStartHour(res.data[0].id);
      setAvailableEndHour(res.data[0].id);
    } catch (e) {
      toast.error(
        e?.response?.data?.msg ||
          "Niet in staat om alle beschikbare uren te krijgen."
      );
    }
  };

  const getAvailableDays = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/admin/get-available-days`,
        ApiConfig
      );

      setAvailableDays(res.data);
      setAvailableStartDay(res.data[0].id);
      setAvailableEndDay(res.data[0].id);
    } catch (e) {
      toast.error(
        e?.response?.data?.msg || "Kan niet alle beschikbare dagen krijgen."
      );
    }
  };

  const handleRowClicked = (id) => {
    getCarDetails(id);
    setEditCarId(id);
    setIsEditMode(true);
    setCarModal(true);
    setKentekenUnselectedBrandstof(null);
  };

  const clearFields = () => {
    setKenteken();
    setBrand();
    setType();
    setYear();
    setKilometerStand();
    setKleur();
    setCatalogusWaarde();
    setBrandstof(0);
    setAdditionalNote();
    setLeasePrice();
    setExceedingKmPrice();
    setAvailableStartDay(1);
    setAvailableEndDay(5);
    setAvailableStartHour(1);
    setAvailableEndHour(10);
    //setSelectedRange(1);
    setTotalKilometers();
    setStartDate(dateConverter(new Date()));
    setIncludedMileagePrivate();
    setIncludedMileageBusiness();
    setEndDate(dateConverterEnd());
    setUitvoering();
    setDeviceId();
    setAssetId();
    setKentekenUnselectedBrandstof(null);
    setBrandstofExtra(2);
    setVacationStartDate();
    setVacationEndDate();
    setVacationOpen(0);
    setVacations([]);
    setDamageReportStatus(1);
  };

  const converter = (start_km, trip_km) => {
    if (start_km && trip_km) {
      return parseFloat(start_km) + parseFloat(trip_km);
    } else if (start_km && !trip_km) {
      return parseFloat(start_km);
    } else if (!start_km && trip_km) {
      return parseFloat(trip_km);
    } else {
      return null;
    }
  };

  const getCarDetails = async (id) => {
    try {
      const res = await axios.get(`${API_URL}/car/car/${id}`, ApiConfig);

      const totalKm = await converter(
        res?.data[0].kilometer_stand,
        res?.data[0].trip_in_clouds_km
      );
      let brandstof = res?.data[0].fuel
        ? converterBrandstof(res?.data[0].fuel)
        : 0;
      setVacations(
        res?.data[0]?.vacations?.length > 0 ? res?.data[0].vacations : []
      );
      setKenteken(res?.data[0].kenteken);
      setBrand(res?.data[0].brand);
      setType(res?.data[0].type);
      setYear(res?.data[0].year);
      setKilometerStand(parseFloat(res?.data[0].kilometer_stand));
      setUitvoering(res?.data[0].implementation);
      setKleur(res?.data[0].color);
      setCatalogusWaarde(res?.data[0].list_price);
      setBrandstof(brandstof);
      setAdditionalNote(res?.data[0].additional_note);
      setLeasePrice(res?.data[0].monthly_lease_price);
      setExceedingKmPrice(res?.data[0].exceeding_km_price);
      setAvailableStartDay(res?.data[0].available_start_day_id);
      setAvailableEndDay(res?.data[0].available_end_day_id);
      setAvailableStartHour(res?.data[0].available_start_hour_id);
      setAvailableEndHour(res?.data[0].available_end_hour_id);
      setDeviceId(res?.data[0]?.device_id ? res?.data[0]?.device_id : null);
      setAssetId(res?.data[0]?.asset_id ? res?.data[0]?.asset_id : null);
      setTotalKilometers(totalKm);
      setStartDate(
        res?.data[0]?.start_date
          ? dateConverter(res?.data[0]?.start_date)
          : null
      );
      setEndDate(
        res?.data[0]?.end_time ? dateConverter(res?.data[0]?.end_time) : null
      );
      setIncludedMileagePrivate(
        res?.data[0]?.included_mileage_private
          ? parseFloat(res?.data[0]?.included_mileage_private)
          : null
      );
      setIncludedMileageBusiness(
        res?.data[0]?.included_mileage_business
          ? parseFloat(res?.data[0]?.included_mileage_business)
          : null
      );
      //setSelectedRange(res?.data[0]?.range ? res?.data[0]?.range : 1);
      setTotalPriceSaved(res?.data[0]?.tarief?.savedPrice || null);
      setMonthlyPrice(res?.data[0]?.tarief?.monthlyPayIncBtw || null);
      setBrandstofExtra(res?.data[0]?.fuel_included ? 1 : 2);
    } catch (e) {
      toast.error(e?.response?.data?.msg);
    }
  };

  const dateConverter = (newDate) => {
    let newStartDate = new Date(newDate);
    let start;

    let dd = String(newStartDate.getDate()).padStart(2, "0");
    let mm = String(newStartDate.getMonth() + 1).padStart(2, "0");
    let yyyy = newStartDate.getFullYear();

    start = yyyy + "-" + mm + "-" + dd;
    start = start;
    return start;
  };

  const dateFormatter = (newDate) => {
    let newStartDate = new Date(newDate);
    let start;

    let dd = String(newStartDate.getDate()).padStart(2, "0");
    let mm = String(newStartDate.getMonth() + 1).padStart(2, "0");
    let yyyy = newStartDate.getFullYear();

    start = dd + "-" + mm + "-" + yyyy;
    return start;
  };

  const dateConverterEnd = () => {
    let newStartDate = new Date();
    let start;

    let dd = String(newStartDate.getDate()).padStart(2, "0");
    let mm = String(newStartDate.getMonth() + 1).padStart(2, "0");
    let yyyy = newStartDate.getFullYear() + 3;

    start = yyyy + "-" + mm + "-" + dd;
    start = start;
    return start;
  };
  /*
  const rangeHandler = () => {
    let newStartDate = new Date(startDate);
    let start;

    let dd = String(newStartDate.getDate()).padStart(2, "0");
    let mm = String(newStartDate.getMonth() + 1).padStart(2, "0");
    let yyyy = newStartDate.getFullYear();

    start = yyyy + "-" + mm + "-" + dd;
    start = start;

    let endYear = newStartDate.getFullYear() + parseInt(selectedRange);
    let end = newStartDate;
    let enddd = String(end.getDate()).padStart(2, "0");
    let endmm = String(end.getMonth() + 1).padStart(2, "0");

    end = endYear + "-" + endmm + "-" + enddd;
    end = end;

    setEndDate(end);
  };


  useEffect(() => {
    if (selectedRange && startDate) {
      rangeHandler();
    }
  }, [selectedRange, startDate]);*/

  const submitAuthorizationHandler = (e) => {
    //if (adminType != 'Bedrijf') {
    if (isEditMode && adminType != "Bedrijf") {
      editCarOnSubmit(e);
    } else if (isEditMode && adminType == "Bedrijf") {
      modifyVacationPeriodsAsCompany(e);
    } else if (!isEditMode && adminType != "Bedrijf") {
      createCarOnSubmit(e);
    }
    //}
  };

  const createCarOnSubmit = async (e) => {
    e.preventDefault();

    if (!brandstof || brandstof == 0) {
      toast.warning("Kies een brandstof.");
      return false;
    }

    if (!startDate || !endDate) {
      toast.warning(
        "Gelieve de begin- en einddatum van de looptijd op te geven."
      );
      return false;
    }

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      toast.warning(
        "De einddatum van de looptijd kan niet kleiner zijn dan de begindatum van de looptijd."
      );
      return false;
    }

    if (availableEndDay < availableStartDay) {
      toast.warning(
        "U kunt geen verleden dag als einde beschikbare dag geven."
      );
      return false;
    }

    if (availableEndHour <= availableStartHour) {
      toast.warning(
        "De beschikbare uren kunnen niet gelijk zijn en het einduur kan niet kleiner zijn dan het beginuur."
      );
      return false;
    }

    let adminId = null;

    if (adminType == "Leasemaatschappij") {
      adminId = await localStorage.getItem("id");
    }

    if (!includedMileagePrivate) {
      toast.warning("Geef de Privé inbegrepen kilometers (per jaar)");
      return false;
    }

    if (!includedMileageBusiness) {
      toast.warning("Geef de Zakelijk inbegrepen kilometers (per jaar)");
      return false;
    }

    const bodyParameters = {
      kenteken,
      brand,
      type,
      year: year,
      kilometer_stand: kilometerStand,
      admin_id: adminId ? parseInt(adminId) : null,
      implementation: uitvoering,
      color: kleur,
      list_price: parseFloat(catalogusWaarde),
      fuel: brandstof ? converterBrandstofForValue(brandstof) : null,
      additional_note: additionalNote ? additionalNote : null,
      monthly_lease_price: leasePrice ? leasePrice : null,
      exceeding_km_price: exceedingKmPrice ? exceedingKmPrice : null,
      available_start_day_id: availableStartDay,
      available_end_day_id: availableEndDay,
      available_start_hour_id: availableStartHour,
      available_end_hour_id: availableEndHour,
      device_id: deviceId ? deviceId : null,
      start_date: startDate ? startDate : null,
      end_time: endDate ? endDate : null,
      included_mileage_business: includedMileageBusiness,
      included_mileage_private: includedMileagePrivate,
      fuel_included: brandstofExtra == 1 ? true : false,
      // range: selectedRange ? parseInt(selectedRange) : 0,
      vacations: vacations?.length > 0 ? vacations : [],
    };

    await axios
      .post(`${API_URL}/car/create-car`, bodyParameters, ApiConfig)
      .then(function (response) {
        toast.success(response.data.msg);
        getData();
        setCarModal(false);
        clearFields();
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const modifyVacationPeriodsAsCompany = async (e) => {
    e.preventDefault();

    const bodyParameters = {
      vacations,
    };

    await axios
      .post(
        `${API_URL}/car/company-vacation-period-modify/${editCarId}`,
        bodyParameters,
        ApiConfig
      )
      .then(function (response) {
        toast.success(response.data.msg);
        getData();
        setCarModal(false);
        clearFields();
        setEditCarId(null);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const editCarOnSubmit = async (e) => {
    e.preventDefault();

    if (!brandstof || brandstof == 0) {
      toast.warning("Kies een brandstof.");
      return false;
    }

    if (!availableEndDay >= availableStartDay) {
      toast.warning(
        "U kunt geen verleden dag als einde beschikbare dag geven."
      );
      return false;
    }

    if (availableEndHour <= availableStartHour) {
      toast.warning(
        "De beschikbare uren kunnen niet gelijk zijn en het einduur kan niet kleiner zijn dan het beginuur."
      );
      return false;
    }

    const bodyParameters = {
      kenteken,
      brand,
      type,
      year: year,
      kilometer_stand: kilometerStand,
      implementation: uitvoering,
      color: kleur,
      list_price: parseFloat(catalogusWaarde),
      fuel: brandstof ? converterBrandstofForValue(brandstof) : null,
      additional_note: additionalNote ? additionalNote : null,
      monthly_lease_price: leasePrice ? leasePrice : null,
      exceeding_km_price: exceedingKmPrice ? exceedingKmPrice : null,
      available_start_day_id: availableStartDay,
      available_end_day_id: availableEndDay,
      available_start_hour_id: availableStartHour,
      available_end_hour_id: availableEndHour,
      device_id: deviceId ? parseInt(deviceId) : null,
      included_mileage_business: includedMileageBusiness
        ? includedMileageBusiness
        : null,
      included_mileage_private: includedMileagePrivate
        ? includedMileagePrivate
        : null,
      fuel_included: brandstofExtra == 1 ? true : false,
      vacations: vacations?.length > 0 ? vacations : [],
    };

    await axios
      .put(`${API_URL}/car/edit-car/${editCarId}`, bodyParameters, ApiConfig)
      .then(function (response) {
        toast.success(response.data.msg);
        getData();
        setCarModal(false);
        clearFields();
        setEditCarId(null);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/car/cars`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan auto gegevens niet ophalen.");
      });
  };

  const getGebruikersOfBedrijfWithNoCar = async () => {
    await axios
      .get(`${API_URL}/car/customers-nocar`, ApiConfig)
      .then(function (response) {
        setGebruikerData(response.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const getAssignedCarOfBedrijf = async () => {
    await axios
      .get(`${API_URL}/car/assigned-car`, ApiConfig)
      .then(function (response) {
        setAssignedCarData(response.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const handleAssign = async (id) => {
    const bodyParameters = {
      company_id: id,
      car_id: linkCarId,
    };

    await axios
      .post(`${API_URL}/car/assign-car-to-company`, bodyParameters, ApiConfig)
      .then(function (response) {
        toast.success("Auto is toegewezen aan bedrijf!");
        setBedrijfLinkModal(false);
        setLinkCarId(null);
        getData();
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const handleLink = async (id) => {
    const bodyParameters = {
      customer_id: id,
      car_id: linkCarId,
    };

    await axios
      .post(`${API_URL}/car/link-car-and-customer`, bodyParameters, ApiConfig)
      .then(function (response) {
        toast.success("Auto is gelinkt aan gebruiker!");
        setLinkModal(false);
        setLinkCarId(null);
        getAssignedCarOfBedrijf();
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const getAvailableBedrijven = async () => {
    await axios
      .get(`${API_URL}/admin/available-companies`, ApiConfig)
      .then(function (response) {
        setBedrijvenData(response.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const onRemoveDeviceFromCar = async () => {
    try {
      await axios.delete(
        `${API_URL}/car/remove-asset/${deleteCarId}`,
        ApiConfig
      );
      toast.success("Apparaat is verwijderd uit de auto.");
      getData();
      setRemoveDeviceModal(false);
    } catch (e) {
      toast.error(e?.response?.data?.msg);
    }
  };

  const handleDelete = async () => {
    await axios
      .delete(`${API_URL}/car/cars/delete/${deleteCarId}`, ApiConfig)
      .then((res) => {
        getData();
        toast.success("Auto is verwijderd.");
        setModal(false);
      })
      .catch((e) => {
        toast.error("Kan auto niet verwijderen.");
      });
  };

  const converterForTitle = () => {
    if (adminType == "Bedrijf") {
      return "Vakantieperiodes Bewerken";
    } else if (isEditMode) {
      return "Voertuig bewerken";
    } else {
      return "Voertuig toevoegen";
    }
  };

  const brandstofExtras = [
    { id: 1, label: "Ja" },
    { id: 2, label: "Nee" },
  ];

  const onDamageReportClick = async (id) => {
    try {
      const res = await axios.get(
        `${API_URL}/car/car-damage-reports/${id}`,
        ApiConfig
      );

      setDamageReports(res.data);
      setDamageReportModal(true);
    } catch (e) {
      toast.error(e?.response?.data?.msg);
    }
  };

  const onKenteken = async () => {
    try {
      const responseOne = await axios.get(
        `https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${kenteken
          .toUpperCase()
          .replaceAll("-", "")}`
      );
      const responseTwo = await axios.get(
        `https://opendata.rdw.nl/resource/8ys7-d773.json?kenteken=${kenteken
          .toUpperCase()
          .replaceAll("-", "")}`
      );

      setCatalogusWaarde(responseOne.data[0].catalogusprijs);
      setKleur(responseOne.data[0].eerste_kleur);
      setBrand(responseOne.data[0].merk);
      setType(responseOne.data[0].handelsbenaming);
      setUitvoering(responseOne.data[0].inrichting);
      setYear(
        responseOne.data[0].datum_eerste_toelating.substring(0, 4) &&
          parseInt(responseOne.data[0].datum_eerste_toelating.substring(0, 4))
      );

      let brandstofResult =
        responseTwo.data.length === 2
          ? "Hybride"
          : responseTwo.data[0].brandstof_omschrijving;

      brandstofResult = brandstofResult
        ? await converterBrandstof(brandstofResult)
        : 0;
      console.log(brandstofResult);
      if (brandstofResult == 0 || !brandstofResult) {
        if (responseTwo.data.length > 1) {
          if (responseTwo.data[0].brandstof_omschrijving == "Diesel") {
            console.log(responseTwo.data[0].brandstof_omschrijving);
            setKentekenUnselectedBrandstof("Elektrisch + Diesel");
            setBrandstof(converterBrandstof("Elektrisch + Diesel"));
          } else {
            setKentekenUnselectedBrandstof("Elektrisch + Benzine");
            setBrandstof(converterBrandstof("Elektrisch + Benzine"));
          }
        } else {
          setKentekenUnselectedBrandstof(
            responseTwo.data[0].brandstof_omschrijving
          );
        }
        setKentekenUnselectedBrandstof(
          responseTwo.data.length === 2
            ? "Hybride"
            : responseTwo.data[0].brandstof_omschrijving
        );
      } else {
        setBrandstof(brandstofResult);
      }
    } catch (e) {
      console.log(e);
      console.log(e.message);
      toast.error("Kan geen gegevens ophalen op basis van dit kenteken.");
    }
  };

  useEffect(() => {
    getAvailableHours();
    getAvailableDays();
  }, []);

  useEffect(() => {
    getData();
    const type = localStorage.getItem("type");
    setAdminType(type);
  }, []);

  useEffect(() => {
    const adminType = localStorage.getItem("type");
    if (adminType == "Bedrijf") {
      getAssignedCarOfBedrijf();
    }
  }, []);

  useEffect(() => {
    if (bedrijfLinkModal) {
      getAvailableBedrijven();
    }
  }, [bedrijfLinkModal]);

  useEffect(() => {
    if (linkModal) {
      getGebruikersOfBedrijfWithNoCar();
    }
  }, [linkModal]);

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Merk",
      selector: "brand",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Jaar",
      selector: "year",
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.is_available === true ? "Beschikbaar" : "Niet beschikbaar",
      sortable: true,
      center: true,
    },
    {
      name: "",
      minWidth: "300px",
      width: "300px",
      cell: (row, index, column, id) => {
        if (adminType === "Bedrijf") {
          if (row?.is_available) {
            return (
              <p
                style={{ minWidth: "100px" }}
                className="bg-success py-2 px-4 text-white rounded-lg text-center"
                onClick={() => {
                  setLinkCarId(row?.id);
                  setLinkModal(true);
                }}
              >
                Koppelen
              </p>
            );
          } else if (!row?.device_id && !row?.asset_id && !row?.asset_code) {
            return (
              <p
                style={{ minWidth: "100px" }}
                className="bg-danger py-2 px-4 text-white rounded-lg text-center"
                onClick={() => {
                  setLinkCarId(row?.id);
                  setLinkModal(true);
                }}
              >
                Geen Apparaat
              </p>
            );
          } else {
            return (
              <p
                style={{ minWidth: "100px" }}
                className="bg-danger py-2 px-4 text-white rounded-lg text-center"
                disabled
              >
                Reeds gekoppeld
              </p>
            );
          }
        } else {
          return (
            <p
              style={{ minWidth: "100px" }}
              className="bg-danger my-2 py-2 px-2 text-black rounded-lg text-center"
              onClick={() => {
                setDeleteCarId(row?.id);
                setModal(true);
              }}
            >
              Verwijder
            </p>
          );
        }
      },
      sortable: true,
      center: true,
    },
  ];

  const tableColumnsCompany = [
    {
      name: "Bedrijf ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Bedrijfsnaam",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <button
            className="bg-success py-2 px-4 text-white rounded-lg text-center border-0"
            onClick={() => {
              handleAssign(row?.id);
            }}
          >
            Toewijzen
          </button>
        );
      },
    },
  ];

  const tableColumnsDamageReports = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Voertuig ID",
      selector: "car_id",
      sortable: true,
      center: true,
    },
    {
      name: "Kenteken",
      selector: "kenteken",
      sortable: true,
      center: true,
    },
    {
      name: "Datum",
      selector: "date",
      sortable: true,
      center: true,
      cell: (row, index, column, id) => {
        return <p>{dateFormatter(row.date)}</p>;
      },
    },
    {
      name: "Aanmaakdatum",
      selector: "created_at",
      sortable: true,
      center: true,
      cell: (row, index, column, id) => {
        return <p>{dateFormatter(row.created_at)}</p>;
      },
    },
    {
      minWidth: "200px",
      width: "200px",
      name: "",
      cell: (row, index, column, id) => {
        return (
          <button
            className="bg-success py-2 px-4 text-white rounded-lg text-center border-0"
            onClick={() => {
              setSelectedDamageReport(row);
              setDamageReportStatus(2);
            }}
          >
            Bekijk meer
          </button>
        );
      },
    },
  ];

  const leaseCompanyTableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Merk",
      selector: "brand",
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },
    {
      name: "Jaar",
      selector: "year",
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.is_available === true ? "Beschikbaar" : "Niet beschikbaar",
      sortable: true,
      center: true,
    },
    {
      name: "",
      minWidth: "160px",
      cell: (row, index, column, id) => {
        if (row?.assigned_company_id) {
          return (
            <p
              style={{ minWidth: "140px" }}
              className="bg-primary py-2 px-2 my-2 text-white rounded-lg text-center"
              disabled
            >
              Gekoppeld
            </p>
          );
        } else if (!row?.device_id && !row?.asset_id && !row?.asset_code) {
          return (
            <p
              style={{ minWidth: "140px" }}
              className="bg-danger py-2 px-2 my-2  text-white rounded-lg text-center"
            >
              Niet Beschikbaar
            </p>
          );
        } else {
          return (
            <p
              style={{ minWidth: "140px" }}
              className="bg-success py-2 px-2 my-2  text-white rounded-lg text-center"
              onClick={() => {
                setLinkCarId(row?.id);
                setBedrijfLinkModal(true);
              }}
            >
              Koppel
            </p>
          );
        }
      },
    },
    {
      name: "",
      minWidth: "160px",
      cell: (row, index, column, id) => {
        if (row?.device_id && row?.asset_id && row?.asset_code) {
          return (
            <div
              className="flex justify-center items-center "
              onClick={(e) => {
                setDeleteCarId(row?.id);
                setRemoveDeviceModal(true);
              }}
            >
              <button
                type="button"
                className="bg-success py-2 px-2 my-2 h-full  text-white rounded-lg text-center border-0"
              >
                <p>Apparaat aan</p>
              </button>
              <i
                style={{ marginLeft: "3px" }}
                className="fa fa-trash text-danger"
              ></i>
            </div>
          );
        } else {
          return (
            <p className="bg-danger py-2 px-2 my-2 h-full  text-white rounded-lg text-center border-0">
              Geen apparaat
            </p>
          );
        }
      },
    },
    {
      name: "",
      minWidth: "160px",
      cell: (row, index, column, id) => {
        return (
          <p
            style={{ minWidth: "140px" }}
            className="bg-secondary py-2 px-2 my-2 text-white rounded-lg text-center"
            onClick={() => {
              setDamageReportStatus(1);
              onDamageReportClick(row?.id);
            }}
          >
            Schaderapporten
          </p>
        );
      },
    },
    {
      name: "",
      minWidth: "160px",
      cell: (row, index, column, id) => {
        if (adminType === "Bedrijf") {
          if (row?.is_available) {
            return (
              <p
                style={{ minWidth: "90px" }}
                className="bg-success my-2 py-2 px-2s text-white rounded-lg text-center"
                onClick={() => {
                  setLinkCarId(row?.id);
                  setLinkModal(true);
                }}
              >
                Koppel
              </p>
            );
          } else {
            return (
              <p
                style={{ minWidth: "90px" }}
                className="bg-danger my-2 py-2 px-2 text-white rounded-lg text-center"
                disabled
              >
                Reeds gekoppeld
              </p>
            );
          }
        } else {
          return (
            <p
              style={{ minWidth: "90px" }}
              className="bg-danger my-2 py-2 px-2 text-white rounded-lg text-center"
              onClick={() => {
                setDeleteCarId(row?.id);
                setModal(true);
              }}
            >
              Verwijder
            </p>
          );
        }
      },
      sortable: true,
      center: true,
    },
  ];

  const gebruikersTableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Voornaam",
      selector: "first_name",
      sortable: true,
      center: true,
    },
    {
      name: "Achternaam",
      selector: "last_name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <button
            className="bg-success border-0 py-2 px-4 text-white rounded-lg text-center"
            onClick={() => {
              handleLink(row?.id);
            }}
          >
            Link
          </button>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  const disablerHandler = () => {
    if (isEditMode || adminType == "Bedrijf") {
      return true;
    } else {
      return false;
    }
  };

  const handlerKilometerStand = (md, exception) => {
    if (exception && isEditMode) {
      return "6";
    } else if (adminType == "Bedrijf") {
      return "12";
    } else if (!isEditMode) {
      return "12";
    } else if (md) {
      return "12";
    } else {
      return "12";
    }
  };

  const deviceIdDisableHandler = () => {
    if ((deviceId && assetId) || adminType == "Bedrijf") {
      return true;
    } else {
      return false;
    }
  };

  const vacationStateHandler = (prev, newState) => {
    if (prev == 0 && (newState == 1 || newState == 2)) {
      setVacationOpen(newState);
    } else if (prev == 1 && newState == 1) {
      setVacationOpen(0);
    } else if (prev == 1 && newState == 2) {
      setVacationOpen(2);
    } else if (prev == 2 && newState == 2) {
      setVacationOpen(0);
    } else if (prev == 2 && newState == 1) {
      setVacationOpen(1);
    }
  };

  const ShowVacationButtons = () => {
    return (
      <div className="w-100 w-full d-flex justify-content-around align-items-center my-2 flex-column flex-lg-row">
        <Button
          color="primary"
          onClick={(prev) =>
            vacations?.length > 0 && vacationStateHandler(vacationOpen, 1)
          }
        >
          Toegevoegde vakantieperiodes tonen (
          {vacations?.filter((item) => !item.deleted).length || 0})
        </Button>

        <Button
          className="mt-2 mt-lg-0"
          color="primary"
          onClick={(prev) => vacationStateHandler(vacationOpen, 2)}
        >
          Een nieuwe vakantieperiode toevoegen
        </Button>
      </div>
    );
  };

  const ShowAllVacations = () => {
    if (vacationOpen == 1) {
      return (
        <Collapse isOpen={vacationOpen == 1} className="w-100 w-full my-3">
          <Table bordered className="w-100 w-full">
            <thead className="w-100 w-full">
              <tr>
                <th>#</th>
                <th>Begin Datum</th>
                <th>Einddatum</th>
                <th>Verwijderen</th>
              </tr>
            </thead>
            <tbody>
              {vacations?.map((item, index) => {
                if (!item?.deleted) {
                  return (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td className="my-auto">
                        <div className="d-flex justify-content-center align-items-center mb-0">
                          <p className="d-flex justify-content-center align-items-center mb-0">
                            {dateConverter(item?.start_date)}
                          </p>
                        </div>
                      </td>
                      <td className="my-auto">
                        {" "}
                        <div className="d-flex justify-content-center align-items-center mb-0">
                          <p className="d-flex justify-content-center align-items-center mb-0">
                            {dateConverter(item?.end_date)}
                          </p>
                        </div>
                      </td>
                      <td>
                        <i
                          style={{ marginLeft: "3px" }}
                          className="fa fa-trash text-danger cursor-pointer-smartcar"
                          onClick={() => vacationRemoveHandler(index)}
                        ></i>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </Collapse>
      );
    } else if (vacationOpen == 2) {
      return (
        <Collapse isOpen={vacationOpen == 2} className="w-100 w-full my-3">
          <div className="w-full d-flex justify-content-between align-items-center flex-column flex-md-row">
            <Col xs="12" sm="5" md="5" lg="5">
              <FormGroup>
                <Label className="form-label">Begindatum</Label>
                <Input
                  className="form-control text-center w-full mx-auto"
                  type="date"
                  value={vacationStartDate}
                  onChange={(e) => setVacationStartDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="5" md="5" lg="5" className="mt-2 mt-md-0">
              <FormGroup>
                <Label className="form-label">Einddatum</Label>
                <Input
                  className="form-control text-center w-full mx-auto"
                  type="date"
                  value={vacationEndDate}
                  onChange={(e) => setVacationEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="1" md="1" lg="1" className="mt-2 mt-md-4">
              <div>
                <i
                  style={{ marginLeft: "12px" }}
                  className="fa fa-plus text-primary cursor-pointer-smartcar"
                  onClick={() =>
                    vacationAddHandler(vacationStartDate, vacationEndDate)
                  }
                ></i>
              </div>
            </Col>
          </div>
        </Collapse>
      );
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Voertuigen" title="Voertuigen" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="text-md-left ">
                <span>Beheer hier je wagenpark</span>
              </CardHeader>
              <CardBody>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className={
                      adminType == "Bedrijf"
                        ? "hidden"
                        : "btn btn-primary px-md-4 px-2 ml-md-0 mr-2"
                    }
                    onClick={() => {
                      clearFields();
                      setIsEditMode(false);
                      setCarModal(true);
                    }}
                  >
                    Voertuig toevoegen
                  </button>
                </div>

                <DataTable
                  data={adminType == "Bedrijf" ? assignedCarData : data}
                  columns={
                    adminType == "Leasemaatschappij" ||
                    adminType == "Super Admin"
                      ? leaseCompanyTableColumns
                      : tableColumns
                  }
                  empty
                  striped={true}
                  center={true}
                  pagination={true}
                  pointerOnHover={true}
                  persistTableHead
                  onRowClicked={(e) => handleRowClicked(e.id)}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen Voertuig met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={carModal} toggle={() => setCarModal(false)} size="lg">
          <ModalHeader toggle={() => setCarModal(false)}>
            {converterForTitle()}
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <Row>
              <Form onSubmit={(e) => submitAuthorizationHandler(e)}>
                <CardHeader>
                  <h4 className="card-title mb-0 text-center">
                    {converterForTitle()}
                  </h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <Label className="form-label">Kenteken</Label>
                        <Input
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          type="text"
                          placeholder=""
                          required
                          value={kenteken}
                          onChange={(e) => setKenteken(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      sm="12"
                      md="12"
                      lg="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className={
                        adminType == "Bedrijf"
                          ? "hidden"
                          : "mt-md-1 mb-xl-2 mb-lg-3 mb-md-4 mb-4"
                      }
                    >
                      <Button
                        disabled={adminType == "Bedrijf" ? true : false}
                        onClick={() => {
                          onKenteken();
                        }}
                        type="button"
                        className="w-full py-2 px-2 text-sm text-blue-800 uppercase font-bold bg-yellow-500 hover:bg-yellow-400 transition duration-200 flex justify-center"
                      >
                        Haal kenteken gegevens op
                      </Button>
                    </Col>
                    <Col sm="6" md="12" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Cataloguswaarde</Label>
                        <Input
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          type="number"
                          required
                          value={catalogusWaarde}
                          min={0}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          onChange={(e) => setCatalogusWaarde(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    {/* 
                        <Col
                          sm="6"
                          md="6"
                          lg="6"
                          className={adminType == "Bedrijf" ? "hidden" : "mb-2"}
                        >
                          <FormGroup>
                            <Label className="form-label">
                              Totale bespaarde prijs
                            </Label>
                            <Input
                              disabled
                              value={
                                totalPriceSaved
                                  ? priceConverter(totalPriceSaved)
                                  : null
                              }
                              className="form-control text-center"
                            />
                          </FormGroup>
                        </Col>*/}
                    {isEditMode && (
                      <>
                        {" "}
                        <Col
                          md="12"
                          lg="6"
                          className={adminType == "Bedrijf" ? "hidden" : "mb-2"}
                        >
                          <FormGroup>
                            <Label className="form-label">
                              Maandelijkse abonnement prijs inc BTW
                            </Label>
                            <Input
                              disabled
                              value={
                                monthlyPrice
                                  ? priceConverter(monthlyPrice)
                                  : null
                              }
                              className="form-control text-center"
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    <Col sm="6" md="6" lg="6" className={"mb-2"}>
                      <FormGroup>
                        <Label className="form-label">Merk</Label>
                        <Input
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          type="text"
                          required
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Type</Label>
                        <Input
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          type="text"
                          required
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Jaar</Label>
                        <Input
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          type="number"
                          required
                          min={0}
                          value={year}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          onChange={(e) => setYear(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label flex justify-center items-center">
                          Brandstof{" "}
                        </Label>
                        <select
                          className="form-control text-center"
                          size="1"
                          ref={brandstofRef}
                          disabled={adminType == "Bedrijf" ? true : false}
                          onChange={(e) => setBrandstof(e.target.value)}
                        >
                          {types.map((option, i) => (
                            <option
                              key={option?.id}
                              value={option?.id}
                              defaultValue={0}
                              selected={option?.id == brandstof}
                            >
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label flex justify-center items-center">
                          Brandstof inbegrepen{" "}
                        </Label>
                        <select
                          className="form-control text-center"
                          size="1"
                          disabled={adminType == "Bedrijf" ? true : false}
                          onChange={(e) => setBrandstofExtra(e.target.value)}
                        >
                          {brandstofExtras.map((option, i) => (
                            <option
                              key={option?.id}
                              value={option?.id}
                              defaultValue={2}
                              selected={option?.id == brandstofExtra}
                            >
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Kleur</Label>
                        <Input
                          className="form-control text-center"
                          type="text"
                          required
                          value={kleur}
                          disabled={adminType == "Bedrijf" ? true : false}
                          onChange={(e) => setKleur(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      sm={adminType == "Bedrijf" ? "6" : "6"}
                      md={adminType == "Bedrijf" ? "12" : "6"}
                      lg={adminType == "Bedrijf" ? "6" : "6"}
                      className="mb-2"
                    >
                      <FormGroup>
                        <Label className="form-label">Uitvoering</Label>
                        <Input
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          type="text"
                          value={uitvoering}
                          onChange={(e) => setUitvoering(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      sm={adminType == "Bedrijf" ? "6" : "6"}
                      md={adminType == "Bedrijf" ? "12" : "6"}
                      lg={
                        adminType == "Bedrijf"
                          ? "12"
                          : handlerKilometerStand(true, true)
                      }
                      className="mb-2"
                    >
                      <FormGroup>
                        <Label className="form-label text-center">
                          Kilometerstand begindatum
                        </Label>

                        <NumberFormat
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          value={priceConverter(kilometerStand)}
                          displayType={"input"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            // formattedValue = $2,223
                            // value ie, 2223

                            setKilometerStand(parseFloat(value));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="12" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Zakelijk inbegrepen kilometers (per jaar)
                        </Label>
                        <NumberFormat
                          required
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          value={priceConverter(includedMileageBusiness)}
                          displayType={"input"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            // formattedValue = $2,223
                            // value ie, 2223

                            setIncludedMileageBusiness(parseFloat(value));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="12" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Privé inbegrepen kilometers (per jaar)
                        </Label>
                        <NumberFormat
                          required
                          disabled={adminType == "Bedrijf" ? true : false}
                          className="form-control text-center"
                          value={priceConverter(includedMileagePrivate)}
                          displayType={"input"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          allowNegative={false}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            // formattedValue = $2,223
                            // value ie, 2223

                            setIncludedMileagePrivate(parseFloat(value));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Looptijd Begindatum
                        </Label>
                        <Input
                          className="form-control text-center w-full mx-auto"
                          type="date"
                          disabled={disablerHandler()}
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Looptijd Einddatum</Label>

                        <Input
                          className="form-control text-center w-full mx-auto"
                          type="date"
                          disabled={disablerHandler()}
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="12" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Lease Prijs (€/Per maand)
                        </Label>
                        <Input
                          step=".01"
                          className="form-control text-center"
                          type="number"
                          value={leasePrice}
                          min={0}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          onChange={(e) => setLeasePrice(e.target.value)}
                          disabled={adminType == "Bedrijf" ? true : false}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="12" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Km prijs (€/Per extra kilometer)
                        </Label>
                        <Input
                          step=".01"
                          className="form-control text-center"
                          type="number"
                          min={0}
                          value={exceedingKmPrice}
                          onKeyDown={(e) => blockInvalidChars(e)}
                          onChange={(e) => setExceedingKmPrice(e.target.value)}
                          disabled={adminType == "Bedrijf" ? true : false}
                        />
                      </FormGroup>
                    </Col>{" "}
                    <Col
                      sm={handlerKilometerStand()}
                      md={handlerKilometerStand(true)}
                      lg={handlerKilometerStand()}
                      className="mb-2"
                    >
                      <FormGroup>
                        <Label className="form-label">
                          Huidige kilometerstand
                        </Label>
                        <Input
                          disabled
                          className="form-control text-center"
                          value={
                            totalKilometers
                              ? priceConverter(totalKilometers)
                              : priceConverter(kilometerStand)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="12" lg="12" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Opmerking</Label>
                        <Input
                          className="form-control text-center"
                          type="text"
                          value={additionalNote}
                          onChange={(e) => setAdditionalNote(e.target.value)}
                          disabled={adminType == "Bedrijf" ? true : false}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Zakelijke startdag</Label>
                        <Input
                          className="form-control text-center"
                          type="select"
                          value={availableStartDay}
                          required
                          onChange={(e) => setAvailableStartDay(e.target.value)}
                          disabled={adminType == "Bedrijf" ? true : false}
                        >
                          {availableDays?.map((day, index) => (
                            <option key={index} value={day?.id}>
                              {day?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">Zakelijke einddag</Label>
                        <Input
                          className="form-control text-center"
                          type="select"
                          value={availableEndDay}
                          required
                          onChange={(e) => setAvailableEndDay(e.target.value)}
                          disabled={adminType == "Bedrijf" ? true : false}
                        >
                          {availableDays?.map((day, index) => (
                            <option key={index} value={day?.id}>
                              {day?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Beschikbaar Start Uur
                        </Label>
                        <Input
                          className="form-control text-center"
                          type="select"
                          value={availableStartHour}
                          required
                          onChange={(e) =>
                            setAvailableStartHour(e.target.value)
                          }
                          disabled={adminType == "Bedrijf" ? true : false}
                        >
                          {availableHours?.map((hour, index) => (
                            <option key={index} value={hour?.id}>
                              {hour?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6" className="mb-2">
                      <FormGroup>
                        <Label className="form-label">
                          Beschikbaar Eind Uur
                        </Label>
                        <Input
                          className="form-control text-center"
                          type="select"
                          value={availableEndHour}
                          required
                          onChange={(e) => setAvailableEndHour(e.target.value)}
                          disabled={adminType == "Bedrijf" ? true : false}
                        >
                          {availableHours?.map((hour, index) => (
                            <option key={index} value={hour?.id}>
                              {hour?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="w-100 w-full">
                        <ShowVacationButtons />
                        {ShowAllVacations()}
                      </div>
                    </div>
                    <Col sm="12" md="12" lg="12" className="mb-2">
                      <FormGroup>
                        <div className="flex justify-center items-center">
                          <Label className="form-label">Apparaat-ID</Label>
                        </div>
                        <Input
                          className={
                            deviceId && assetId
                              ? "form-control text-center bg-gray"
                              : "form-control text-center"
                          }
                          disabled={deviceIdDisableHandler()}
                          type="number"
                          value={deviceId}
                          min="0"
                          onKeyDown={(e) => blockInvalidChars(e, true)}
                          onChange={(e) => setDeviceId(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter
                  className={"text-center"} // adminType == 'Bedrijf' ? 'hidden' : 'text-center'
                >
                  <button
                    className={
                      "btn btn-primary" //   adminType == 'Bedrijf' ? 'hidden' : 'btn btn-primary'
                    }
                    type="submit"
                    disabled={false} // adminType == 'Bedrijf' ? true : false
                  >
                    {converterForTitle()}
                  </button>
                </CardFooter>
              </Form>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={linkModal} toggle={() => setLinkModal(false)} size="lg">
          <ModalHeader toggle={() => setLinkModal(false)}>
            Link een auto aan een gebruiker
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <DataTable
              data={gebruikerData}
              columns={gebruikersTableColumns}
              striped={true}
              center={true}
              pointerOnHover={true}
              persistTableHead
              pagination={true}
              noDataComponent={
                <p style={{ padding: 20 }}>
                  Er zijn geen gebruikers met deze specificatie.
                </p>
              }
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={bedrijfLinkModal}
          toggle={() => setBedrijfLinkModal(false)}
          size="lg"
        >
          <ModalHeader toggle={() => setBedrijfLinkModal(false)}>
            Welk bedrijf mag deze auto gebruiken?
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <DataTable
              data={bedrijvenData}
              columns={tableColumnsCompany}
              striped={true}
              center={true}
              pointerOnHover={true}
              persistTableHead
              pagination={true}
              noDataComponent={
                <p style={{ padding: 20 }}>
                  Er zijn geen bedrijven met deze specificatie.
                </p>
              }
            />
          </ModalBody>
        </Modal>

        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}>
            Weet je zeker dat je deze auto wilt verwijderen?
          </ModalHeader>
          <ModalBody>
            Als je de auto verwijderd zal de gerelateerde gebruiker ook geen
            auto meer hebben. Deze kan je wel een nieuwe auto weer toewijzen.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => handleDelete()}>
              Verwijderen
            </Button>{" "}
            <Button color="grey" onClick={() => setModal(!modal)}>
              Annuleren
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={removeDeviceModal}
          toggle={() => setRemoveDeviceModal(!removeDeviceModal)}
        >
          <ModalHeader toggle={() => setRemoveDeviceModal(!removeDeviceModal)}>
            Weet u zeker dat u het apparaat wilt verwijderen?
          </ModalHeader>
          <ModalBody>
            Nadat u dit apparaat uit de auto hebt verwijderd, kunt u het
            apparaat met een andere auto activeren, maar het zal uit de
            geselecteerde auto worden verwijderd.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => onRemoveDeviceFromCar()}>
              Verwijderen
            </Button>{" "}
            <Button
              color="grey"
              onClick={() => setRemoveDeviceModal(!removeDeviceModal)}
            >
              Annuleren
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={damageReportModal}
          toggle={() => setDamageReportModal(false)}
          size="xl"
        >
          <ModalHeader toggle={() => setDamageReportModal(false)}>
            Zie de ingediende schaderapporten voor deze auto
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            {damageReportStatus == 2 ? (
              <>
                <Row className="px-5">
                  <Col sm="12" md="12" lg="12" className="mb-2">
                    <FormGroup>
                      <Label className="form-label">Afbeelding</Label>
                      <div className="relative flex items-center justify-center md:justify-between">
                        <img
                          className="py-auto mb-2 object-contain mx-auto"
                          src={
                            selectedDamageReport?.image_url &&
                            `${API_URL}/car/car-damage-report/${selectedDamageReport?.image_url}`
                          }
                          alt={"car_damage_report"}
                          width="350px"
                          height="350px"
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm="12" md="12" lg="12" className="mb-2">
                    <FormGroup>
                      <Label className="form-label">Titel</Label>
                      <Input
                        className="form-control text-center"
                        type="text"
                        required
                        disabled
                        value={selectedDamageReport?.title}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="12" className="mb-2">
                    <FormGroup>
                      <Label className="form-label">Beschrijving</Label>
                      <Input
                        disabled
                        rows="3"
                        className="form-control text-center"
                        type="textarea"
                        required
                        value={selectedDamageReport?.description}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <DataTable
                data={damageReports}
                columns={tableColumnsDamageReports}
                striped={true}
                center={true}
                pointerOnHover={true}
                persistTableHead
                pagination={true}
                noDataComponent={
                  <p style={{ padding: 20 }}>
                    Er zijn geen schaderapporten voor deze auto.
                  </p>
                }
              />
            )}
          </ModalBody>
        </Modal>
      </Container>
    </Fragment>
  );
};

export default Voertuigen;
