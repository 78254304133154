import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import {
  Password,
  SignIn,
  EmailAddress,
  RememberPassword,
  API_URL,
} from "../constant";
import axios from "axios";

const Logins = (props) => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${API_URL}/admin/login`, {
        email: email?.toLowerCase(),
        password: password,
      })
      .then(function (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", response.data.user.user_name);
        localStorage.setItem("email", response.data.user.email);
        localStorage.setItem("type", response.data.user.role);
        localStorage.setItem("id", response.data.user.id);
        props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
      })
      .catch(function (error) {
        toast.error("De email/wachtwoord combinatie is onjuist.");
        setLoading(false);
      });
  };

  const forgotPasswordRequest = async (e) => {
    e.preventDefault(e);
    setLoading(true);

    await axios
      .post(`${API_URL}/admin/forgot-password`, {
        email: forgotPasswordEmail.toLowerCase(),
      })
      .then(function (response) {
        toast.success(
          response?.data?.msg
            ? response?.data?.msg
            : "Check je email voor verdere instructies."
        );
        setLoading(false);
      })
      .catch(function (error) {
        toast.error(
          error?.response?.data?.msg
            ? error?.response?.data?.msg
            : "Er is een fout opgetreden, probeer het later opnieuw."
        );
        setLoading(false);
      });
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    src={require("../assets/images/logo/login.png")}
                    width={300}
                    alt="logo"
                  />
                  <img
                    className="img-fluid for-dark"
                    src={require("../assets/images/logo/logo_dark.png")}
                    width={300}
                    alt="logo1"
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                {forgotPassword ? (
                  <Form
                    className="theme-form"
                    onSubmit={(e) =>
                      forgotPassword ? forgotPasswordRequest(e) : loginAuth(e)
                    }
                  >
                    <h4>Wachtwoord vergeten?</h4>
                    <p>{""}</p>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        type="email"
                        required={true}
                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        value={forgotPasswordEmail}
                      />
                    </FormGroup>

                    <div className="form-group mb-0">
                      <Button
                        color="primary"
                        className="btn-block"
                        disabled={loading ? loading : loading}
                      >
                        {loading ? "LADEN..." : "Verzenden"}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <Form className="theme-form" onSubmit={(e) => loginAuth(e)}>
                    <h4>Login om verder te gaan</h4>
                    <p>{""}</p>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        type="email"
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{Password}</Label>
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        required={true}
                        value={password}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </FormGroup>
                    <div className="form-group mb-0">
                      <div className="checkbox ml-3">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">
                          {RememberPassword}
                        </Label>
                      </div>
                      <Button
                        color="primary"
                        className="btn-block"
                        disabled={loading ? loading : loading}
                      >
                        {loading ? "LADEN..." : SignIn}
                      </Button>
                    </div>
                  </Form>
                )}
                <div className="d-flex justify-content-center align-items-center">
                  {forgotPassword ? (
                    <button
                      color="primary"
                      className="text-center mt-3 text-underline text-primary border-0 bg-white"
                      onClick={() => setForgotPassword(false)}
                    >
                      Login
                    </button>
                  ) : (
                    <button
                      color="primary"
                      className="text-center mt-3 text-underline text-primary border-0 bg-white"
                      onClick={() => setForgotPassword(true)}
                    >
                      Wachtwoord vergeten?
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Logins);
