import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { Jwt_token } from "../data/config";
import { toast } from "react-toastify";
import { withRouter, useParams, useHistory } from "react-router-dom";
import {
  Password,
  SignIn,
  EmailAddress,
  RememberPassword,
  API_URL,
} from "../constant";
import axios from "axios";

const ForgotChangePassword = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const history = useHistory();
  const forgot_token = useParams();

  const checkValidateToken = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/admin/validate-forgot-token/${forgot_token.forgot_token}`
      );

      setForgotPassword(res.data || false);
    } catch (e) {
      toast.error("Foute URL, probeer het opnieuw.");
      history.push(`${process.env.PUBLIC_URL}/login`);
    }
  };

  useEffect(() => {
    checkValidateToken();
  }, []);

  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `${API_URL}/admin/forgot-change-password/${forgot_token.forgot_token}`,
        {
          newPassword,
          newPasswordCheck,
        }
      )
      .then(function (response) {
        toast.success("Wachtwoord is successvol veranderd!");
        props.history.push(`${process.env.PUBLIC_URL}/login`);
      })
      .catch(function (error) {
        toast.error(
          error?.response?.data?.msg ||
            "De email/wachtwoord combinatie is onjuist."
        );
        setLoading(false);
      });
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img
                    className="img-fluid for-light"
                    src={require("../assets/images/logo/login.png")}
                    width={300}
                    alt="logo"
                  />
                  <img
                    className="img-fluid for-dark"
                    src={require("../assets/images/logo/logo_dark.png")}
                    width={300}
                    alt="logo1"
                  />
                </a>
              </div>
              <div className="login-main login-tab">
                <Form
                  className="theme-form"
                  onSubmit={(e) => changePassword(e)}
                >
                  <h4>Change your password.</h4>
                  <p>{""}</p>
                  <FormGroup>
                    <Label className="col-form-label">New Password</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      required={true}
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      New Password Confirm
                    </Label>
                    <Input
                      className="form-control"
                      type={toggleNewPassword ? "text" : "password"}
                      onChange={(e) => setNewPasswordCheck(e.target.value)}
                      required={true}
                      value={newPasswordCheck}
                    />
                    <div
                      className="show-hide"
                      onClick={() => setToggleNewPassword(!toggleNewPassword)}
                    >
                      <span className={toggleNewPassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <div className="form-group">
                    <Button
                      color="primary"
                      className="btn-block"
                      disabled={loading ? loading : loading}
                    >
                      {loading ? "LADEN..." : "Change password"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ForgotChangePassword);
