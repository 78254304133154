import {
  Home,
  DollarSign,
  Mail,
  CheckCircle,
  FileText,
  Bell,
  Users,
} from "react-feather";

import { AiFillCar } from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import { FaFileInvoiceDollar, FaBuilding } from "react-icons/fa";

export const MENUITEMS1 = [
  {
    menutitle: "General",
    menucontent: "Dashboard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Gebruikers",
    menucontent:
      "Beheer gebruikers, beheer bedrijven, keur aanvragen goed en bekijk facturen.",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/klant/company`,
        icon: FaBuilding,
        title: "Bedrijven",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/klant`,
        icon: Users,
        title: "Gebruikers",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/klant/aanvragen`,
        icon: Bell,
        title: "Nieuwe aanvragen",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/klant/facturen`,
        icon: FaFileInvoiceDollar,
        title: "Facturen",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Voertuigen",
    menucontent: "Beheer partners en voertuigen.",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/voertuigen`,
        icon: AiFillCar,
        title: "Voertuigen",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/voertuigen/mockup-autos`,
        icon: AiFillCar,
        title: "Mockup auto's",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/voertuigen/partners`,
        icon: BsBuilding,
        title: "Partners",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Communicatie",
    menucontent: "Alle communicatie zoals berichten, bevestigingen & meer",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/communicatie/faq`,
        icon: CheckCircle,
        title: "Faq",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/communicatie/teksten`,
        icon: FileText,
        title: "Beheer teksten",
        type: "link",
      },
    ],
  },
];
