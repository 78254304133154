import { Home, Users } from "react-feather";
import { AiFillCar } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";

export const MENUITEMS2 = [
  {
    menutitle: "General",
    menucontent: "Dashboard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Bedrijven",
    menucontent: "Beheer zakelijke klanten.",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/klant/company`,
        icon: FaBuilding,
        title: "Bedrijven",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Voertuigen",
    menucontent: "Beheer de voertuigen van jouw bedrijf.",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/voertuigen`,
        icon: AiFillCar,
        title: "Voertuigen",
        type: "link",
      },
    ],
  },
];
