import { Home, Users } from "react-feather";
import { AiFillCar } from "react-icons/ai";

export const MENUITEMS3 = [
  {
    menutitle: "General",
    menucontent: "Dashboard",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Gebruikers",
    menucontent: "Beheer jouw werknemers",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/klant`,
        icon: Users,
        title: "Gebruikers",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Voertuigen",
    menucontent:
      "Bekijk beschikbare voertuigen en koppel deze aan je werknemers.",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/voertuigen`,
        icon: AiFillCar,
        title: "Voertuigen",
        type: "link",
      },
    ],
  },
];
