import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Media,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import axios from "axios";
import { EmailAddress, Update } from "../../constant";
import { Link } from "react-router-dom";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";

const CompanyDetails = (props) => {
  const [data, setData] = useState([]);
  const [linkedCustomerData, setLinkedCustomerData] = useState([]);
  const [name, setName] = useState();
  const [adres, setAdres] = useState();
  const [postcode, setPostcode] = useState();
  const [stad, setStad] = useState();
  const [email, setEmail] = useState();
  const [kvk, setKvk] = useState();
  const [btw, setBtw] = useState();
  const [password, setPassword] = useState();
  const [assignedCarsData, setAssignedCarsData] = useState([]);
  const history = useHistory();
  let { id } = useParams();

  const companyTableHeader = [
    "Account ID",
    "Voornaam",
    "Achternaam",
    "Adres",
    "Email",
    "",
  ];

  const tableColumns = [
    {
      name: "Account ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Voornaam",
      selector: "first_name",
      sortable: true,
      center: true,
    },
    {
      name: "Achternaam",
      selector: "last_name",
      sortable: true,
      center: true,
    },
    {
      name: "Adres",
      selector: "address",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Link
            className={
              localStorage.getItem("type") === "Super Admin" ? "" : "hidden"
            }
            to={`/klant/klantdetails/${row?.id}`}
          >
            Meer info
          </Link>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const bodyParameters = {
    name: name === null ? undefined : name,
    email,
    address: adres,
    city: stad,
    zip_code: postcode,
    kvk,
    btw,
    password,
  };

  const updateCompany = async (e) => {
    //Todo: add validation to form
    e.preventDefault();

    await axios
      .put(`${API_URL}/admin/modify-company/${id}`, bodyParameters, ApiConfig)
      .then(function (response) {
        getData();
        toast.success("Bedrijven gegevens zijn geupdate!");
      })
      .catch(function (error) {
        toast.error(error.response.data.msg);
      });
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/admin/company/${id}`, ApiConfig)
      .then((res) => {
        if (!res.data) {
          history.push(`${process.env.PUBLIC_URL}`);
        } else {
          setName(res.data[0].name);
          setKvk(res.data[0].kvk);
          setBtw(res.data[0].btw);
          setAdres(res.data[0].address);
          setPostcode(res.data[0].zip_code);
          setStad(res.data[0].city);
          setEmail(res.data[0].email);
        }
      })
      .catch((e) => {
        toast.error("Kan bedrijven gegevens niet ophalen.");
      });
  };

  const getAssignedCars = async () => {
    await axios
      .get(`${API_URL}/car/assigned-cars-of-company/${id}`, ApiConfig)
      .then((res) => {
        setAssignedCarsData(res.data);
      })
      .catch((e) => {
        toast.error("Kan bedrijven gegevens niet ophalen.");
      });
  };

  const getCustomerData = async () => {
    await axios
      .get(`${API_URL}/admin/linked-customers-to-company/${id}`, ApiConfig)
      .then((res) => {
        setLinkedCustomerData(res.data);
      })
      .catch((e) => {
        toast.error("Kan bedrijven gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    getData();
    getCustomerData();
    getAssignedCars();
  }, []);

  const assignedCarsTableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Merk",
      selector: "brand",
      sortable: true,
      center: true,
    },
    {
      name: "Toegewezen Bedrijf",
      selector: (row) => (row.company_name ? row.company_name : "-"),
      sortable: true,
      center: true,
    },
    {
      name: "Gekoppelde Gebruiker",
      selector: (row) => (row.customer_name ? row.customer_name : "-"),
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      center: true,
    },

    {
      name: "Jaar",
      selector: "year",
      sortable: true,
      center: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.customer_id && row?.car_id ? "Toegewezen" : "Niet toegewezen",
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Details" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Profiel</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <div className="col-auto">
                        <Media
                          className="img-70 rounded-circle"
                          alt=""
                          src={require("../../assets/images/user/7.jpg")}
                        />
                      </div>
                      <Col>
                        <h3 className="mb-1">{name}</h3>
                        <h5 className="mb-4">Bedrijven</h5>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Form className="card" onSubmit={(e) => updateCompany(e)}>
                <CardHeader>
                  <h4 className="card-title mb-0">Gegevens</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="6" md="4" className="mt-md-0 mt-2">
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        <Input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mt-md-0 mt-2">
                      <FormGroup>
                        <Label className="form-label">Bedrijfsnaam</Label>
                        <Input
                          className="form-control"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Kvk</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={kvk}
                          onChange={(e) => setKvk(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="6" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Btw</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={btw}
                          onChange={(e) => setBtw(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Bedrijfsadres</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={adres}
                          onChange={(e) => setAdres(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="4" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Plaats</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          value={stad}
                          onChange={(e) => setStad(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Postcode</Label>
                        <Input
                          className="form-control"
                          placeholder="e.g. 1234 EP"
                          required
                          value={postcode}
                          onChange={(e) => setPostcode(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="3" className="mt-2">
                      <FormGroup>
                        <Label className="form-label">Password</Label>
                        <Input
                          className="form-control"
                          placeholder="*******"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-lg-right text-center">
                  <button className="btn btn-primary" type="submit">
                    Update gegevens
                  </button>
                </CardFooter>
              </Form>
            </Col>
            <Col md="12">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0">
                    Zakelijke accounts gelinkt aan bedrijf
                  </h4>

                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="#javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="#javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={linkedCustomerData}
                    columns={tableColumns}
                    empty
                    striped={true}
                    center={true}
                    pointerOnHover={true}
                    persistTableHead
                    pagination={true}
                    noDataComponent={
                      <p style={{ padding: 20 }}>
                        Deze bedrijven heeft nog geen gebruikers.
                      </p>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <h4 className="card-title mb-0">
                    Auto's toegewezen aan dit bedrijf
                  </h4>

                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="#javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="#javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    data={assignedCarsData}
                    columns={assignedCarsTableColumns}
                    empty
                    striped={true}
                    center={true}
                    pointerOnHover={true}
                    persistTableHead
                    pagination={true}
                    noDataComponent={
                      <p style={{ padding: 20 }}>
                        Deze bedrijven heeft nog geen auto's.
                      </p>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default CompanyDetails;
