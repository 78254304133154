import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Form, Row } from 'reactstrap';
import RightHeader from './rightbar';
import LeftHeader from './leftbar';

const Header = (props) => {
	return (
		<Fragment>
			<div className="page-header">
				<Row className="header-wrapper m-0">
					<LeftHeader />
					<RightHeader />
				</Row>
			</div>
		</Fragment>
	);
};

export default Header;
