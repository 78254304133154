const priceConverter = (price) => {
  //let currencyPrefix = "€ ";
  let convertedPrice = price
    ? parseFloat(price).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : "-";

  /*
  if (price) {
    return currencyPrefix + convertedPrice;
  } else {
    
  }*/

  return convertedPrice;
};

export default priceConverter;
