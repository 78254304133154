import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  Form,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../constant/index";
import DataTable from "react-data-table-component";
import { useRef } from "react";

const Bevestigingen = (props) => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [wijzigenModal, setWijzigenModal] = useState(false);
  const [verwijderenModal, setVerwijderenModal] = useState(false);
  const [verwijderenCategorieModal, setVerwijderenCategorieModal] =
    useState(false);
  const [deleteCategorieModal, setDeleteCategorieModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categorieNaam, setCategorieNaam] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [faqId, setFaqId] = useState();
  const [vraag, setVraag] = useState("");
  const [antwoord, setAntwoord] = useState("");
  const [site, setSite] = useState(1);

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const getFaqCategories = async () => {
    try {
      const res = await axios.get(`${API_URL}/faq/faqcategories`, ApiConfig);
      setCategories(res.data);
      setCategoryId(res.data[0]?.id);
    } catch (e) {
      toast.error("Kan gegevens niet ophalen");
    }
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/faq/faq`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  const faqEdit = async (e) => {
    e.preventDefault();
    try {
      const bodyParams = {
        question: vraag,
        answer: antwoord,
        category_id: categoryId,
        site: site == 1 ? "Moving 4ward" : "M4 Mobility",
        id: faqId,
      };
      await axios.post(`${API_URL}/faq/faq-edit`, bodyParams, ApiConfig);
      toast.success("Vraag is gewijzigd.");
      getData();
      setVraag();
      setAntwoord();
      setSite(1);
      setCategorieNaam();
      setCategoryId();
      setFaqId();
      setWijzigenModal();
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const faqDelete = async () => {
    try {
      const bodyParams = {
        id: faqId,
      };
      await axios.post(`${API_URL}/faq/delete-faq`, bodyParams, ApiConfig);
      toast.success("Vraag is verwijderd.");
      getData();
      setFaqId();
      setVerwijderenModal(false);
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const handleFaqDelete = () => {
    if (categoryId) {
      setDeleteCategorieModal(false);
      setVerwijderenCategorieModal(true);
    } else {
      toast.error("Geen categorie te verwijderen.");
      return false;
    }
  };

  const faqCatDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}/faq/delete-faqcat/${categoryId}`,
        ApiConfig
      );
      toast.success("Categorie is verwijderd.");
      getData();
      getFaqCategories();

      setVerwijderenCategorieModal(false);
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const faqToevoegen = async (e) => {
    e.preventDefault();

    if (!categoryId) {
      toast.error(
        "Om een Vraag te kunnen maken, moet je eerst een Categorie maken."
      );
      return false;
    }

    try {
      const bodyParams = {
        question: vraag,
        answer: antwoord,
        category_id: parseInt(categoryId),
        site: site == 1 ? "Moving 4ward" : "M4 Mobility",
      };

      await axios.post(`${API_URL}/faq/faq-add`, bodyParams, ApiConfig);
      toast.success("Vraag is toegevoegd.");
      getData();
      setModal(false);
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const addFaqCat = async (e) => {
    e.preventDefault();
    try {
      if (categorieNaam) {
        const faqCatBodyParams = {
          name: categorieNaam.toString(),
        };

        await axios.post(
          `${API_URL}/faq/faqcat-add`,
          faqCatBodyParams,
          ApiConfig
        );
        toast.success("Categorie is toegevoegd.");
        getFaqCategories();
        setCategoryModal(false);
      }
    } catch (e) {
      toast.error("Kan gegevens niet ophalen.");
    }
  };

  const tableColumns = [
    {
      name: "Categorie",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Vraag",
      selector: "question",
      sortable: true,
      center: true,
    },
    {
      name: "Antwoord",
      selector: "answer",
      sortable: true,
      center: true,
    },
    {
      name: "Site",
      selector: (row) => row?.site,
      sortable: true,
      center: true,
    },
    {
      name: "",
      minWidth: "110px",
      cell: (row, index, column, id) => {
        return (
          <Button
            className="px-md-3 py-md-2 p-1 my-1"
            color="success"
            onClick={() => {
              setVraag(row?.question);
              setAntwoord(row?.answer);
              setSite(row?.site == "Moving 4ward" ? 1 : 2);
              setCategorieNaam(row?.name);
              setCategoryId(parseInt(row?.category_id));
              setFaqId(row?.id);
              setWijzigenModal(true);
            }}
            size="sm"
          >
            Vraag wijzigen
          </Button>
        );
      },
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    getData();
    getFaqCategories();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Communicatie" title="FAQ" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>FAQ Categorieën</h5>
              </CardHeader>
              <CardBody>
                <div className="d-flex flex-md-row flex-column justify-content-between justify-content-md-center align-items-center mb-0 h-full">
                  <Button
                    color="primary"
                    onClick={() => {
                      setVraag();
                      setAntwoord();
                      setSite(1);
                      setCategorieNaam();
                      setCategoryId(categories[0]?.id);
                      setFaqId();
                      setModal(true);
                    }}
                    size="sm"
                    className=" ml-2 mb-md-0 mb-2 h-100"
                  >
                    Vraag Toevoegen
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setCategorieNaam();
                      setCategoryModal(true);
                    }}
                    size="sm"
                    className="mx-md-2 ml-2 mb-md-0 mb-2 h-100"
                  >
                    Nieuwe categorie toevoegen
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setDeleteCategorieModal(true);
                    }}
                    size="sm"
                    className="ml-2 mb-0 h-100"
                  >
                    Verwijder een categorie
                  </Button>
                </div>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  empty
                  striped={true}
                  pagination={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen FAQ met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={categoryModal}
        toggle={() => setCategoryModal(false)}
        size="lg"
      >
        <ModalHeader toggle={() => setCategoryModal(false)}>
          Faq categorie aanmaken
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form onSubmit={(e) => addFaqCat(e)}>
            <Row>
              <Col>
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Naam van de categorie"
                  value={categorieNaam}
                  onChange={(e) => setCategorieNaam(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <Button color="primary" style={{ marginTop: 20 }} type="submit">
              FAQ categorie aanmaken
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={verwijderenModal}
        toggle={() => setVerwijderenModal(false)}
        size="lg"
      >
        <ModalHeader toggle={() => setVerwijderenModal(false)}>
          Verwijder faq
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Row>
            <Col>
              <h5>Weet je zeker dat je dit item wilt verwijderen?</h5>
            </Col>
          </Row>
          <Button
            color="light"
            style={{ marginTop: 20, marginRight: 5 }}
            type="submit"
            onClick={() => setVerwijderenModal(false)}
          >
            Annuleer
          </Button>
          <Button
            color="danger"
            style={{ marginTop: 20, marginLeft: 5 }}
            type="submit"
            onClick={() => {
              faqDelete();
            }}
          >
            Verwijder
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={verwijderenCategorieModal}
        toggle={() => setVerwijderenCategorieModal(false)}
        size="lg"
      >
        <ModalHeader toggle={() => setVerwijderenCategorieModal(false)}>
          Verwijder categorie
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Row>
            <Col>
              <h5>
                Weet je zeker dat je deze categorie wilt verwijderen? Hiermee
                worden ook alle vragen die onder deze categorie vallen
                verwijderd
              </h5>
            </Col>
          </Row>
          <Button
            color="light"
            style={{ marginTop: 20, marginRight: 5 }}
            type="submit"
            onClick={() => setVerwijderenCategorieModal(false)}
          >
            Annuleer
          </Button>
          <Button
            color="danger"
            style={{ marginTop: 20, marginLeft: 5 }}
            type="submit"
            onClick={() => {
              faqCatDelete();
            }}
          >
            Verwijder
          </Button>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteCategorieModal}
        toggle={() => {
          setDeleteCategorieModal(false);
        }}
        size="lg"
      >
        <ModalHeader toggle={() => setDeleteCategorieModal(false)}>
          Verwijder een categorie
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form>
            <Row>
              <Col>
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                  className="form-control"
                  placeholder="Voer een categorie in"
                  type="select"
                  name="select"
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  {categories?.map((category, index) => (
                    <option key={index} value={category?.id}>
                      {category?.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Button
              color="light"
              style={{ marginTop: 20, marginRight: 5 }}
              onClick={() => setDeleteCategorieModal(false)}
            >
              Annuleer
            </Button>
            <Button
              color="danger"
              style={{ marginTop: 20, marginLeft: 5 }}
              onClick={() => handleFaqDelete()}
            >
              Verwijder
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={wijzigenModal}
        toggle={() => {
          setWijzigenModal(false);
        }}
        size="lg"
      >
        <ModalHeader toggle={() => setWijzigenModal(false)}>
          Faq wijzigen
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form onSubmit={(e) => faqEdit(e)}>
            <Row>
              <Col>
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                  className="form-control"
                  placeholder="Voer een categorie in"
                  type="select"
                  name="select"
                  defaultValue={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  {categories?.map((category) => (
                    <option
                      key={category?.id}
                      value={category?.id}
                      selected={category?.id == categoryId}
                    >
                      {category?.name}
                    </option>
                  ))}
                </Input>

                <Input
                  className="form-control"
                  type="text"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  placeholder="Vraag"
                  value={vraag}
                  onChange={(e) => setVraag(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="textarea"
                  placeholder="Antwoord"
                  value={antwoord}
                  rows="5"
                  onChange={(e) => setAntwoord(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="select"
                  name="select"
                  defaultValue={site}
                  onChange={(e) => setSite(parseInt(e.target.value))}
                >
                  <option value={1}>4-Ward</option>
                  <option value={2}>M4 Mobility</option>
                </Input>
              </Col>
            </Row>
            <Button
              color="light"
              style={{ marginTop: 20, marginRight: 5 }}
              onClick={() => setWijzigenModal(false)}
            >
              Annuleer
            </Button>
            <Button
              color="danger"
              style={{ marginTop: 20, marginLeft: 5 }}
              onClick={() => {
                setWijzigenModal(false);
                setVerwijderenModal(true);
              }}
            >
              Verwijderen
            </Button>
            <Button
              color="success"
              style={{ marginTop: 20, marginLeft: 5 }}
              type="submit"
            >
              Opslaan
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader toggle={() => setModal(false)}>
          Vraag Toevoegen
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form onSubmit={(e) => faqToevoegen(e)}>
            <Row>
              <Col>
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                  className="form-control"
                  placeholder="Voer een categorie in"
                  type="select"
                  name="select"
                  id="vraag-toevoegen-select"
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  {categories?.map((category) => (
                    <option key={category?.id} value={category?.id}>
                      {category?.name}
                    </option>
                  ))}
                </Input>

                <Input
                  className="form-control"
                  type="text"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  placeholder="Vraag"
                  value={vraag}
                  onChange={(e) => setVraag(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="textarea"
                  placeholder="Antwoord"
                  rows="5"
                  value={antwoord}
                  onChange={(e) => setAntwoord(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="select"
                  name="select"
                  onChange={(e) => setSite(parseInt(e.target.value))}
                >
                  <option selected value={1}>
                    4-Ward
                  </option>
                  <option value={2}>M4 Mobility</option>
                </Input>
              </Col>
            </Row>
            <Button color="primary" style={{ marginTop: 20 }} type="submit">
              Aanmaken
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Bevestigingen;
