import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import axios from "axios";
import { API_URL } from "../../constant";
import { toast } from "react-toastify";

const Berichten = (props) => {
  const [data, setData] = useState([]);
  const tableColumns = ["ID", "Email"];

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/superadmin/newsletter-subscribers`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Communicatie" title="Berichten" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Nieuwsbrief abonnee's</h5>
              </CardHeader>
              <CardBody>
                <table className="table card-table table-vcenter text-nowrap">
                  <thead>
                    <tr>
                      {tableColumns.map((items, i) => (
                        <th key={i}>{items}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((items, i) => (
                      <tr key={i} style={{ cursor: "pointer" }}>
                        <td>{items?.id}</td>
                        <td>{items?.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Berichten;
