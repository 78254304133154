import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { API_URL } from "../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";

const Aanvragen = (props) => {
  const [data, setData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [user, setUser] = useState({
    id: "",
    type: "",
  });

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const bodyParameters = {
    key: "value",
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/superadmin/unapproved_customers`, ApiConfig)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toast.error("Kan data niet ophalen.");
      });

    await axios
      .get(`${API_URL}/superadmin/unapproved_companies`, ApiConfig)
      .then((res) => {
        setCompanyData(res.data);
      })
      .catch((e) => {
        toast.error("Kan data niet ophalen.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const tableColumns = [
    {
      name: "Gebruiker ID",
      selector: "id",
      sortable: true,
      center: true,
      compact: true,
      minWidth: "120px",
    },
    {
      name: "Naam",
      selector: (row, index) => {
        return row.first_name + " " + row.last_name;
      },
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Site",
      selector: (row, index) => {
        return row.site == 1 ? "Zakelijk" : "Particulier";
      },
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="success"
            size="sm"
            className="py-2 px-2"
            style={{ minWidth: "110px" }}
            onClick={() => openGoedkeurModal(row?.id, row?.kvk)}
          >
            Goedkeuren
          </Button>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="danger"
            size="sm"
            className="ml-2 py-2 px-2"
            style={{ minWidth: "110px" }}
            onClick={() => openAfkeurModal(row?.id, row?.kvk)}
          >
            Afkeuren
          </Button>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="transparant"
            size="sm"
            className="ml-2 py-2 px-2"
            onClick={() => handleMoreInfo(row?.id, row?.kvk)}
          >
            Meer info
          </Button>
        );
      },
      minWidth: "110px",
      sortable: true,
      center: true,
    },
  ];

  const tableColumnsCompany = [
    {
      name: "Bedrijf ID",
      selector: "id",
      sortable: true,
      center: true,
      textAlign: "center",
      compact: true,
      minWidth: "120px",
    },
    {
      name: "Bedrijfsnaam",
      selector: "name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="success"
            size="sm"
            className="py-2 px-2"
            onClick={() => openGoedkeurModal(row?.id, row?.kvk)}
            style={{ minWidth: "110px" }}
          >
            Goedkeuren
          </Button>
        );
      },

      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="danger"
            size="sm"
            className="ml-2 py-2 px-2"
            style={{ minWidth: "110px" }}
            onClick={() => openAfkeurModal(row?.id, row?.kvk)}
          >
            Afkeuren
          </Button>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="transparant"
            size="sm"
            className="ml-2 py-2 px-2"
            onClick={() => handleMoreInfo(row?.id, row?.kvk)}
          >
            Meer info
          </Button>
        );
      },
      minWidth: "110px",
      sortable: true,
      center: true,
    },
  ];

  const handleMoreInfo = (userId, kvk) => {
    localStorage.setItem("userid", userId);
    if (kvk) {
      history.push(`${process.env.PUBLIC_URL}/klant/companydetails/${userId}`);
    } else {
      history.push(`${process.env.PUBLIC_URL}/klant/klantdetails/${userId}`);
    }
  };

  const openGoedkeurModal = (id, kvk) => {
    if (!kvk) {
      setModal(true);
      setUser({ id, type: "customer" });
    } else {
      setModal2(true);
      setUser({ id, type: "company" });
    }
  };

  const openAfkeurModal = (id, kvk) => {
    if (!kvk) {
      setModal1(true);
      setUser({ id, type: "customer" });
    } else {
      setModal3(true);
      setUser({ id, type: "company" });
    }
  };

  const handleApprove = () => {
    if (user.id != "") {
      if (user.type == "customer") {
        axios
          .post(
            `${API_URL}/superadmin/approve_customer/${user.id}`,
            bodyParameters,
            ApiConfig
          )
          .then(function (response) {
            setModal(false);
            getData();
            toast.success("Gebruiker is goedgekeurd!");
          })
          .catch(function (error) {
            setModal(false);
            toast.error("Er is een fout opgetreden.");
          });
      } else if (user.type == "company") {
        axios
          .post(
            `${API_URL}/superadmin/approve_company/${user.id}`,
            bodyParameters,
            ApiConfig
          )
          .then(function (response) {
            setModal2(false);
            getData();
            toast.success("Bedrijf is goedgekeurd!");
          })
          .catch(function (error) {
            setModal2(false);
            toast.error("Er is een fout opgetreden.");
          });
      }
    } else {
      setModal2(false);
      toast.error("Er is een fout opgetreden.");
    }
  };

  const handleDisapprove = () => {
    if (user.id != "") {
      if (user.type == "customer") {
        axios
          .post(
            `${API_URL}/superadmin/disapprove_customer/${user.id}`,
            bodyParameters,
            ApiConfig
          )
          .then(function () {
            setModal1(false);
            getData();
            toast.success(
              "Gebruiker is verwijderd en heeft een email ontvangen."
            );
          })
          .catch(function (error) {
            setModal1(false);
            toast.error("Er is een fout opgetreden.");
          });
      } else if (user.type == "company") {
        axios
          .post(
            `${API_URL}/superadmin/disapprove_company/${user.id}`,
            bodyParameters,
            ApiConfig
          )
          .then(function () {
            setModal3(false);
            getData();
            toast.success(
              "Bedrijf is verwijderd en heeft een email ontvangen."
            );
          })
          .catch(function (error) {
            setModal3(false);
            toast.error("Er is een fout opgetreden.");
          });
      }
    } else {
      setModal1(false);
      setModal3(false);
      toast.error("Er is een fout opgetreden.");
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Aanvragen" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="text-md-left mt-md-0 mt-4">
                <h5 className="mb-md-0 mb-2">
                  Aanvragen van nieuwe gebruikers en bedrijven
                </h5>
                <span>
                  {"Bekijk nieuwe aanvragen van gebruikers en bedrijven"}
                </span>
              </CardHeader>
              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  empty
                  striped={true}
                  pagination={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen gebruikers aanvragen met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
              <CardBody>
                <DataTable
                  data={companyData}
                  columns={tableColumnsCompany}
                  empty
                  striped={true}
                  pagination={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen bedrijven aanvragen met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          Weet je zeker dat je deze gebruiker wilt goedkeuren?
        </ModalHeader>
        <ModalBody>
          Als je de gebruiker goedkeurt kan hij gebruik maken van het platform.
          Hij ontvangt daarnaast een email met dat hij goedgekeurd is.
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => handleApprove()}>
            Goedkeuren
          </Button>{" "}
          <Button color="grey" onClick={() => setModal(!modal)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal1} toggle={() => setModal1(!modal1)}>
        <ModalHeader toggle={() => setModal1(!modal1)}>
          Weet je zeker dat je deze gebruiker wilt afkeuren?
        </ModalHeader>
        <ModalBody>
          Als je de gebruiker afkeurt wordt zijn account verwijderd. Hij
          ontvangt een email dat zijn account is afgekeurd.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleDisapprove()}>
            Afkeuren
          </Button>{" "}
          <Button color="grey" onClick={() => setModal1(!modal1)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2} toggle={() => setModal2(!modal2)}>
        <ModalHeader toggle={() => setModal2(!modal2)}>
          Weet je zeker dat je deze bedrijven wilt goedkeuren?
        </ModalHeader>
        <ModalBody>
          Als je de bedrijven goedkeurt kan hij gebruik maken van het platform.
          Hij ontvangt daarnaast een email met dat hij goedgekeurd is.
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => handleApprove()}>
            Goedkeuren
          </Button>{" "}
          <Button color="grey" onClick={() => setModal2(!modal2)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal3} toggle={() => setModal3(!modal3)}>
        <ModalHeader toggle={() => setModal3(!modal3)}>
          Weet je zeker dat je deze bedrijven wilt afkeuren?
        </ModalHeader>
        <ModalBody>
          Als je de bedrijven afkeurt wordt zijn account verwijderd. Hij
          ontvangt een email dat zijn account is afgekeurd.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => handleDisapprove()}>
            Afkeuren
          </Button>{" "}
          <Button color="grey" onClick={() => setModal3(!modal3)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Aanvragen;
