// dashbaord
import Default from "../components/dashboard/default";

// widgets
import GeneralWidget from "../components/widgets/general";
import ChartsWidget from "../components/widgets/charts";

// ui-kits
import StateColor from "../components/ui-kits/statecolor";
import Typography from "../components/ui-kits/typography";
import Avatars from "../components/ui-kits/avatars";
import HelperClasses from "../components/ui-kits/helperClasses";
import Grid from "../components/ui-kits/grid";
import TagAndPills from "../components/ui-kits/tagAndPills";
import Progress from "../components/ui-kits/progress";
import Modal from "../components/ui-kits/modal";
import Alert from "../components/ui-kits/alert";
import Popover from "../components/ui-kits/popover";
import Tooltip from "../components/ui-kits/tooltip";
import Spinner from "../components/ui-kits/spinner";
import Dropdown from "../components/ui-kits/dropDown";
import TabBootstrap from "../components/ui-kits/tabs/tabBootstrap";
import TabLine from "../components/ui-kits/tabs/tabLine";
import Accordian from "../components/ui-kits/accordian";
import Shadow from "../components/ui-kits/shadow";
import List from "../components/ui-kits/list";

// bonus-ui
import Scrolling from "../components/bonus_ui/scrolling";
import BootstrapNotify from "../components/bonus_ui/bootstrapNotify";
import Rating from "../components/bonus_ui/rating";
import Dropzone from "../components/bonus_ui/dropzone";
import SweetAlert from "../components/bonus_ui/sweetAlert";
import Tour from "../components/bonus_ui/tour";
import Sticky from "../components/bonus_ui/sticky";
import Breadcrumb from "../components/bonus_ui/breadcrumb";
import Imagecrop from "../components/bonus_ui/imagecrop";
import RangeSlider from "../components/bonus_ui/rangeSlider";
import Carousel from "../components/bonus_ui/carousel";
import Ribbons from "../components/bonus_ui/ribbons";
import Pagination from "../components/bonus_ui/pagination";
import DragAndDrop from "../components/bonus_ui/dragAndDrop";
import UploadImage from "../components/bonus_ui/uploadImage";

// Timeline
import Timeline1 from "../components/bonus_ui/timelines/timeline";

// Icons
import FlagIcons from "../components/icons/flagIcons";
import FontAwsomeIcon from "../components/icons/fontAwsomeIcon";
import IcoIcons from "../components/icons/icoIcons";
import ThemifyIcons from "../components/icons/themifyIcons";
import FeatherIcons from "../components/icons/featherIcons";
import WeatherIcons from "../components/icons/weatherIcons";

import Materialdesignicon from "../components/icons/materialdesignicon";
import Pe7icons from "../components/icons/pe7icons";
import Typicon from "../components/icons/typicon";
import Simplelineicon from "../components/icons/simplelineicon";
import Ionicicon from "../components/icons/ionic-icon";

// Buttons
import DefaultButton from "../components/buttons/default-Button";
import EdgeButton from "../components/buttons/edgeButton";
import FlatButton from "../components/buttons/flatButton";
import GroupButton from "../components/buttons/groupButton";
import RaisedButton from "../components/buttons/raisedButton";

// Forms
import FormValidation from "../components/forms/form-control/form-validation";
import BaseInput from "../components/forms/form-control/baseInput";
import InputGroup from "../components/forms/form-control/inputGroup";
import MegaOption from "../components/forms/form-control/megaOption";
import CheckboxandRadio from "../components/forms/form-control/checkboxandRadio";

// Form Layout
import FormDefault from "../components/forms/form-layout/formDefault";
import FormWizard1 from "../components/forms/form-layout/form-wizard-1/formwizard1";

// Forms widgets
import Datepicker from "../components/forms/form-widget/datepicker";
import Timepicker from "../components/forms/form-widget/timepickerComponent/timepicker";
import Typeahead from "../components/forms/form-widget/typeaheadComponent/typeahead";

// Tabels
import BasicTabels from "../components/tables/basicTable";
import BorderTable from "../components/tables/borderTable";
import SizingTable from "../components/tables/sizingTable";
import StylingTable from "../components/tables/stylingTable";
import DataTable from "../components/tables/dataTable";

// Charts
import Apexcharts from "../components/charts/apexCharts";
import KnobChart from "../components/charts/knobChart";
import Chartsjs from "../components/charts/chartsjs";
import Chartist from "../components/charts/chartistCharts";

// Gallary
import ImageGallery from "../components/gallery/imageGallery";
import ImageWithDesc from "../components/gallery/imageWithDesc";
import MesonryGallery from "../components/gallery/mesonryGallery";
import MesonryDesc from "../components/gallery/mesonryDesc";
import ImageHover from "../components/gallery/imageHover";

// Blog
import BlogDetail from "../components/blog/blogDetail";
import BlogSingle from "../components/blog/blogSingle";
import BlogPost from "../components/blog/blogPost";

// Job Search
import CardView from "../components/jobSearch/cardView";
import JobList from "../components/jobSearch/job-list";
import JobDetail from "../components/jobSearch/job-detail";
import JobApply from "../components/jobSearch/job-apply";

// Learning
import Learninglist from "../components/learning/learning-list";
import LearningDeatil from "../components/learning/learning-deatil";

// Users
import UserEdit from "../components/users/userEdit";
import UserCards from "../components/users/userCards";

// Editor
import CkEditor from "../components/editor/ckEditor";
import MdeEditor from "../components/editor/mdeEditor";

// Social App
import SocialApp from "../components/social-app";

// knowledgebase page
import KnowledgebaseComponent from "../components/knowledgebase";

// Support Ticket
import SupportTicket from "../components/support-ticket";

// Sample page
import Sample from "../components/sample";

// Search page
import Search from "../components/search";

// E-commerce-app
import Product from "../components/application/ecommerce-app/product";
import ProductDetail from "../components/application/ecommerce-app/productpage";
import Cart from "../components/application/ecommerce-app/cart";
import Wishlist from "../components/application/ecommerce-app/wishlist";
import Productlist from "../components/application/ecommerce-app/productlist";
import Paymentdetails from "../components/application/ecommerce-app/paymentdetails";
import OrderHistory from "../components/application/ecommerce-app/orderHistory";
import Checkout from "../components/application/ecommerce-app/checkout";
import Invoice from "../components/application/ecommerce-app/invoice";
import Pricing from "../components/application/ecommerce-app/pricing";

// Email
import Email from "../components/application/email-app/emailDefault";

// Chat
import Chat from "../components/application/chat-app";

// Bookmark
import Bookmark from "../components/application/bookmark";

// Project app
import ProjectList from "../components/application/project/project";
import NewProject from "../components/application/project/new-project";

// File Manager App
import FileManager from "../components/application/file-manager/file-manager";

// Task
import TaskApp from "../components/application/task-app";

// Contact app
import ContactApp from "../components/application/contact-app";

// todo-app
import Todo from "../components/application/todo-app";

// todo-firebase-app
import TodoFirebase from "../components/application/todo-firebase-app";

//Klant
import Facturen from "../components/klant/facturen";
import NieuwFactuur from "../components/klant/nieuwFactuur";
import Klant from "../components/klant/index";
import KlantDetails from "../components/klant/klantdetails";
import CompanyDetails from "../components/klant/companydetails";
import Company from "../components/klant/company";
import Ritten from "../components/klant/ritten";
import Aanvragen from "../components/klant/aanvragen";
import AutoLinken from "../components/klant/autoLinken";

//Voertuigen
import Voertuigen from "../components/voertuigen/index";
import MockupAutos from "../components/voertuigen/mockupAutos";
import Leasemaatschappijen from "../components/voertuigen/leasemaatschappijen";

import LeasemaatschappijDetail from "../components/voertuigen/leasemaatschappijDetail";

//Communicatie
import Berichten from "../components/communicatie/berichten";
import Bevestigingen from "../components/communicatie/faq";
import Teksten from "../components/communicatie/teksten";
import TekstenDetail from "../components/communicatie/tekstendetail";

//Admin
import Instellingen from "../components/instellingen";

export const routes = [
  { path: "/dashboard", Component: Default },

  { path: "/widgets/general", Component: GeneralWidget },
  { path: "/widgets/chart", Component: ChartsWidget },

  { path: "/ui-kits/statecolor", Component: StateColor },
  { path: "/ui-kits/typography", Component: Typography },
  { path: "/ui-kits/avatar", Component: Avatars },
  { path: "/ui-kits/helperclass", Component: HelperClasses },
  { path: "/ui-kits/grid", Component: Grid },
  { path: "/ui-kits/tagsandpills", Component: TagAndPills },
  { path: "/ui-kits/progress-bar", Component: Progress },
  { path: "/ui-kits/modal", Component: Modal },
  { path: "/ui-kits/alert", Component: Alert },
  { path: "/ui-kits/popover", Component: Popover },
  { path: "/ui-kits/tooltips", Component: Tooltip },
  { path: "/ui-kits/spinner", Component: Spinner },
  { path: "/ui-kits/dropdown", Component: Dropdown },
  { path: "/ui-kits/tab-bootstrap", Component: TabBootstrap },
  { path: "/ui-kits/tab-line", Component: TabLine },
  { path: "/ui-kits/accordion", Component: Accordian },
  { path: "/ui-kits/shadow", Component: Shadow },
  { path: "/ui-kits/list", Component: List },
  { path: "/ui-kits/shadow", Component: Shadow },
  { path: "/ui-kits/list", Component: List },

  //Klant Routes
  { path: "/klant/facturen", Component: Facturen },
  { path: "/klant/nieuwfactuur", Component: NieuwFactuur },
  { path: "/klant", Component: Klant },
  { path: "/klant/klantdetails/:id", Component: KlantDetails },
  { path: "/klant/companydetails/:id", Component: CompanyDetails },
  { path: "/klant/company", Component: Company },
  { path: "/klant/ritten/:id", Component: Ritten },
  { path: "/klant/aanvragen", Component: Aanvragen },
  { path: "/klant/autolinken", Component: AutoLinken },

  //Voertuigen Routes
  { path: "/voertuigen", Component: Voertuigen },
  { path: "/voertuigen/mockup-autos", Component: MockupAutos },
  { path: "/voertuigen/partners", Component: Leasemaatschappijen },
  {
    path: "/voertuigen/partners/detail",
    Component: LeasemaatschappijDetail,
  },

  //Communicatie Routes
  { path: "/communicatie/faq", Component: Bevestigingen },
  { path: "/communicatie/berichten", Component: Berichten },
  { path: "/communicatie/teksten", Component: Teksten },
  { path: "/communicatie/tekstendetail/:type", Component: TekstenDetail },

  //Admin Routes
  { path: "/instellingen", Component: Instellingen },

  //Company routes

  { path: "/bonus-ui/scrollable", Component: Scrolling },
  { path: "/bonus-ui/bootstrap-notify", Component: BootstrapNotify },
  { path: "/bonus-ui/rating", Component: Rating },
  { path: "/bonus-ui/dropzone", Component: Dropzone },
  { path: "/bonus-ui/sweetAlert", Component: SweetAlert },
  { path: "/bonus-ui/tourComponent", Component: Tour },
  { path: "/bonus-ui/stickyNotes", Component: Sticky },
  { path: "/bonus-ui/breadcrumb", Component: Breadcrumb },
  { path: "/bonus-ui/imageCropper", Component: Imagecrop },
  { path: "/bonus-ui/rangeSlider", Component: RangeSlider },
  { path: "/bonus-ui/carousel", Component: Carousel },
  { path: "/bonus-ui/ribbons", Component: Ribbons },
  { path: "/bonus-ui/pagination", Component: Pagination },
  { path: "/bonus-ui/dragNDropComp", Component: DragAndDrop },
  { path: "/bonus-ui/image-upload", Component: UploadImage },

  { path: "/bonus-ui/timelines/timeline1", Component: Timeline1 },

  { path: "/icons/flagIcons", Component: FlagIcons },
  { path: "/icons/fontAwsomeIcon", Component: FontAwsomeIcon },
  { path: "/icons/icoIcons", Component: IcoIcons },
  { path: "/icons/featherIcons", Component: FeatherIcons },
  { path: "/icons/themifyIcons", Component: ThemifyIcons },
  { path: "/icons/weatherIcons", Component: WeatherIcons },

  { path: "/icons/material-design-icon", Component: Materialdesignicon },
  { path: "/icons/pe7-icon", Component: Pe7icons },
  { path: "/icons/typicons-icon", Component: Typicon },
  { path: "/icons/simplelineIcon", Component: Simplelineicon },
  { path: "/icons/ionic-icon", Component: Ionicicon },

  { path: "/buttons/default-btn", Component: DefaultButton },
  { path: "/buttons/flatBtn", Component: FlatButton },
  { path: "/buttons/edgeBtn", Component: EdgeButton },
  { path: "/buttons/raisedBtn", Component: RaisedButton },
  { path: "/buttons/groupBtn", Component: GroupButton },

  { path: "/forms/form-validation", Component: FormValidation },
  { path: "/forms/baseInput", Component: BaseInput },
  { path: "/forms/inputGroup", Component: InputGroup },
  { path: "/forms/megaOptions", Component: MegaOption },
  { path: "/forms/radio-checkbox", Component: CheckboxandRadio },
  { path: "/form-layout/formDefault", Component: FormDefault },
  { path: "/form-layout/formWizard", Component: FormWizard1 },

  { path: "/form-widget/datepicker", Component: Datepicker },
  { path: "/form-widget/timepicker", Component: Timepicker },
  { path: "/form-widget/typeahead", Component: Typeahead },

  { path: "/table/basic", Component: BasicTabels },
  { path: "/table/border", Component: BorderTable },
  { path: "/table/sizing", Component: SizingTable },
  { path: "/table/styling", Component: StylingTable },
  { path: "/table/datatable", Component: DataTable },

  { path: "/charts/apexCharts", Component: Apexcharts },
  { path: "/charts/knobChart", Component: KnobChart },
  { path: "/charts/chartJs", Component: Chartsjs },
  { path: "/charts/chartistComponent", Component: Chartist },

  { path: "/app/gallery/imageGallery", Component: ImageGallery },
  { path: "/app/gallery/imageWithDesc", Component: ImageWithDesc },
  { path: "/app/gallery/mesonryGallery", Component: MesonryGallery },
  { path: "/app/gallery/mesonryDesc", Component: MesonryDesc },
  { path: "/app/gallery/imageHover", Component: ImageHover },

  { path: "/app/blog/blogDetail", Component: BlogDetail },
  { path: "/app/blog/blogSingle", Component: BlogSingle },
  { path: "/app/blog/blogPost", Component: BlogPost },

  { path: "/app/jobSearch/cardView", Component: CardView },
  { path: "/app/jobSearch/job-list", Component: JobList },
  { path: "/app/jobSearch/job-detail", Component: JobDetail },
  { path: "/app/jobSearch/job-apply", Component: JobApply },

  { path: "/app/learning/learning-list", Component: Learninglist },
  { path: "/app/learning/learning-detail", Component: LearningDeatil },

  { path: "/app/users/userEdit", Component: UserEdit },
  { path: "/app/users/userCards", Component: UserCards },

  { path: "/app/editor/ckEditor", Component: CkEditor },
  { path: "/app/editor/mdeEditor", Component: MdeEditor },

  { path: "/app/social-app", Component: SocialApp },
  { path: "/app/knowledgebase", Component: KnowledgebaseComponent },
  { path: "/app/support-ticket", Component: SupportTicket },

  { path: "/pages/samplepage", Component: Sample },
  { path: "/pages/searchpage", Component: Search },

  { path: "/app/ecommerce/product", Component: Product },
  { path: "/app/ecommerce/product-page/:id", Component: ProductDetail },
  { path: "/app/ecommerce/cart", Component: Cart },
  { path: "/app/ecommerce/wishlist", Component: Wishlist },
  { path: "/app/ecommerce/checkout", Component: Checkout },
  { path: "/app/ecommerce/invoice", Component: Invoice },
  { path: "/app/ecommerce/product-list", Component: Productlist },
  { path: "/app/ecommerce/payment-details", Component: Paymentdetails },
  { path: "/app/ecommerce/orderhistory", Component: OrderHistory },
  { path: "/app/ecommerce/pricing", Component: Pricing },

  { path: "/app/email-app", Component: Email },
  { path: "/app/chat-app", Component: Chat },
  { path: "/app/bookmark", Component: Bookmark },
  { path: "/app/task", Component: TaskApp },

  { path: "/app/project/project-list", Component: ProjectList },
  { path: "/app/project/new-project", Component: NewProject },

  { path: "/app/file-manager", Component: FileManager },

  { path: "/app/contact", Component: ContactApp },

  { path: "/app/todo-app/todo", Component: Todo },
  { path: "/app/todo-app/todo-firebase", Component: TodoFirebase },
];
