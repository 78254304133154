import React, { Fragment, useState, useEffect } from "react";
import { LogIn, User, Bell, Minimize } from "react-feather";
import { useHistory } from "react-router-dom";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from "react-switch-lang";

import {
  Notification,
  DeliveryProcessing,
  CheckAllNotification,
  Account,
  LogOut,
} from "../../constant";

//Optional
import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";
import pt from "../../assets/i18n/pt.json";
import fr from "../../assets/i18n/fr.json";
import du from "../../assets/i18n/du.json";
import cn from "../../assets/i18n/cn.json";
import ae from "../../assets/i18n/ae.json";

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage("en");
setLanguageCookie();

const Rightbar = (props) => {
  const history = useHistory();
  const [profile, setProfile] = useState(
    require("../../assets/images/user/profile.png")
  );
  const [name, setName] = useState("");
  const [moonlight, setMoonlight] = useState(false);
  const [selected, setSelected] = useState("en");
  const [type, setType] = useState("");
  const [notificationDropDown, setNotificationDropDown] = useState(false);

  // auth0 profile
  const handleSetLanguage = (key) => {
    setLanguage(key);
    setSelected(key);
  };

  useEffect(() => {
    setName(localStorage.getItem("username"));
    setType(localStorage.getItem("type"));
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  const Logout = () => {
    localStorage.clear();
    history.push(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history.push(redirect);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  return (
    <Fragment>
      <div className="nav-right ml-auto pull-right right-header p-0">
        <ul className="nav-menus">
          {/* 
					<li className="onhover-dropdown">
						<div className="mode" onClick={() => setNotificationDropDown(!notificationDropDown)}>
							<Bell />
							<span className="badge badge-pill badge-secondary">2</span>
						</div>
						<ul
							className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}
						>
							<li>
								<Bell />
								<h6 className="f-18 mb-0">{Notification}</h6>
							</li>
							<li>
								<p>
									<i className="fa fa-circle-o mr-3 font-primary"> </i>
									{DeliveryProcessing} <span className="pull-right">{'10 min.'}</span>
								</p>
							</li>
							<li>
								<button className="btn btn-primary">{CheckAllNotification}</button>
							</li>
						</ul>
					</li>*/}
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li>
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div
              className="media profile-media"
              style={{ display: "flex", marginLeft: "10px" }}
            >
              <img className="b-r-10" src={profile} alt="" />
              <div className="media-body">
                <span>{name}</span>
                <p className="mb-0 font-roboto">
                  {type === "Leasemaatschappij" ? "Partner" : type}{" "}
                  <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li
                onClick={() =>
                  UserMenuRedirect(`${process.env.PUBLIC_URL}/instellingen`)
                }
              >
                <User />
                <span>{Account} </span>
              </li>
              <li onClick={Logout}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default translate(Rightbar);
