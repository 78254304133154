import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Media,
  FormGroup,
  Form,
  CardBody,
  CardFooter,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";
import { API_URL } from "../constant";
import { toast } from "react-toastify";

const Instellingen = (props) => {
  const [email, setEmail] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [password1, setPassword1] = useState();
  const [insurancePolicy, setInsurancePolicy] = useState();
  const [file, setFile] = useState();
  const [isFileLoading, setIsFileLoading] = useState();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  function LoaderSpinner() {
    return (
      <div className="flex items-center justify-center">
        <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-primary"></div>
      </div>
    );
  }

  const getData = async () => {
    await axios
      .get(
        `${API_URL}/admin/admin-account/${localStorage.getItem("id")}`,
        ApiConfig
      )
      .then((res) => {
        setEmail(res.data[0].email);
        setInsurancePolicy(res?.data[0]?.file ? res?.data[0]?.file : null);
      })
      .catch((e) => {
        toast.error("Kan gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("type") !== "Bedrijf") {
      getData();
    }
  }, []);

  const emailBody = {
    email: email?.toLowerCase(),
  };

  const changeEmail = (e) => {
    e.preventDefault();

    axios
      .post(`${API_URL}/admin/change-email`, emailBody, ApiConfig)
      .then((res) => {
        toast.success("Email is gewijzigd.");
      })
      .catch((e) => {
        toast.error("Er is een error opgetreden.");
      });
  };

  let passwordBody = {
    currentPassword: oldPassword,
    newPassword: password,
    newPasswordCheck: password1,
  };

  const changePassword = async (e) => {
    try {
      //Todo: new endpoint
      e.preventDefault();

      let axiosUrl;
      const id = localStorage.getItem("id");

      if (localStorage.getItem("type") === "Bedrijf") {
        axiosUrl = `${API_URL}/company/change-password/${id}`;
      } else {
        axiosUrl = `${API_URL}/admin/change-password/${id}`;
      }

      if (password === password1) {
        await axios
          .put(axiosUrl, passwordBody, ApiConfig)
          .then((res) => {
            toast.success("Wachtwoord is gewijzigd.");
          })
          .catch((e) => {
            toast.error(e.response.data.msg);
          });
      }
    } catch (e) {
      toast.error(e?.response?.data?.msg || "Wachtwoorden komen niet overeen.");
      return false;
    }
  };

  const uploadInsurancePolicy = async () => {
    try {
      setIsFileLoading(true);

      if (file.size > 10000000) {
        toast.error(
          "Deze verzekeringspolis is te groot, upload een verzekeringspolis die kleiner dan 10mb is."
        );
        return;
      } else {
        setIsFileLoading(false);
      }

      var formData = new FormData();

      formData.append("file", file);

      const response = await axios.post(
        `${API_URL}/admin/upload-insurance-policy`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      getData();
      toast.success("Verzekering upload is succesvol");
      setIsFileLoading(false);
    } catch (e) {
      setIsFileLoading(false);
      toast.error(e?.response?.data?.msg);
    }
  };

  useEffect(() => {
    if (file && localStorage.getItem("type") === "Leasemaatschappij") {
      uploadInsurancePolicy();
    }
  }, [file]);

  return (
    <Fragment>
      <Breadcrumb parent="Admin" title="Instellingen" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8" xl="8">
                      <div className="media">
                        <Media
                          className="img-thumbnail rounded-circle mr-3"
                          src={require("../assets/images/user/profile.png")}
                          alt="Generic placeholder image"
                        />
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">
                            {localStorage.getItem("username")}
                          </h5>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right text-md-right text-center">
                        <small className="font-weight-bold">
                          {localStorage.getItem("type")}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col
                    xl="6"
                    className={
                      localStorage.getItem("type") === "Bedrijf"
                        ? "hidden"
                        : null
                    }
                  >
                    <Form className="card" onSubmit={(e) => changeEmail(e)}>
                      <CardHeader>
                        <h4 className="card-title mb-0 text-md-left text-center">
                          Email wijzigen
                        </h4>
                        <div className="card-options">
                          <a
                            className="card-options-collapse"
                            href="#javascript"
                          >
                            <i className="fe fe-chevron-up"></i>
                          </a>
                          <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody className="mx-lg-0 mx-md-4 mx-4">
                        <Row>
                          <FormGroup style={{ width: "100%" }}>
                            <Label className="form-label">Email</Label>
                            <Input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              width="100%"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-md-right text-center">
                        <button className="btn btn-primary" type="submit">
                          Update email
                        </button>
                      </CardFooter>
                    </Form>
                  </Col>
                  <Col
                    xl={localStorage.getItem("type") === "Bedrijf" ? "12" : "6"}
                  >
                    <Form className="card" onSubmit={(e) => changePassword(e)}>
                      <CardHeader>
                        <h4 className="card-title mb-0 text-md-left text-center">
                          Wachtwoord wijzigen
                        </h4>
                        <div className="card-options">
                          <a
                            className="card-options-collapse"
                            href="#javascript"
                          >
                            <i className="fe fe-chevron-up"></i>
                          </a>
                          <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody className="mx-lg-0 mx-md-4 mx-4">
                        <Row>
                          <FormGroup style={{ width: "100%" }} className="mt-2">
                            <Label className="form-label">
                              Oude wachtwoord
                            </Label>
                            <Input
                              className="form-control"
                              type="password"
                              placeholder=""
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                              required
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "100%" }} className="mt-2">
                            <Label className="form-label">Wachtwoord</Label>
                            <Input
                              className="form-control"
                              type="password"
                              placeholder=""
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </FormGroup>
                          <FormGroup style={{ width: "100%" }} className="mt-2">
                            <Label className="form-label">
                              Herhaal wachtwoord
                            </Label>
                            <Input
                              className="form-control"
                              type="password"
                              placeholder=""
                              value={password1}
                              onChange={(e) => setPassword1(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Row>
                      </CardBody>
                      <CardFooter
                        className={
                          localStorage.getItem("type") === "Bedrijf"
                            ? "text-center"
                            : "text-md-right text-center"
                        }
                      >
                        <button className="btn btn-primary " type="submit">
                          Update wachtwoord
                        </button>
                      </CardFooter>
                    </Form>
                  </Col>
                </Row>

                <Row>
                  <Col
                    xl="6"
                    className={
                      localStorage.getItem("type") === "Leasemaatschappij"
                        ? ""
                        : "hidden"
                    }
                  >
                    <Form className="card">
                      <CardHeader>
                        <h4 className="card-title mb-0 text-md-left text-center">
                          Upload verzekeringspolis{" "}
                        </h4>
                        <div className="card-options">
                          <a
                            className="card-options-collapse"
                            href="#javascript"
                          >
                            <i className="fe fe-chevron-up"></i>
                          </a>
                          <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody className="mx-lg-0 mx-md-4 mx-4">
                        <Row>
                          <FormGroup style={{ width: "100%" }}>
                            <div className="d-flex justify-content-center align-items-center">
                              {insurancePolicy ? (
                                <a
                                  className="btn btn-primary"
                                  download={`$verzekeringspolis_${email}`}
                                  href={`${API_URL}/car/download-insurance-policy/${insurancePolicy}`}
                                >
                                  <span>
                                    Download huidige verzekeringspolis
                                  </span>{" "}
                                  {""} {""}
                                  <i
                                    className="fa fa-download"
                                    style={{ color: "white" }}
                                  ></i>
                                </a>
                              ) : (
                                <Label className="form-label text-center">
                                  Je hebt geen geuploade verzekering op dit
                                  moment.
                                </Label>
                              )}
                            </div>
                          </FormGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-md-right text-center">
                        <label htmlFor="upload-insurance-policy">
                          <span className="btn btn-primary">
                            {isFileLoading ? (
                              <LoaderSpinner />
                            ) : (
                              <>
                                <span style={{ color: "white" }}>Upload</span>
                                {""} {""}
                                <i
                                  className="fa fa-upload w-5 h-5"
                                  style={{ color: "white" }}
                                ></i>{" "}
                              </>
                            )}
                          </span>
                          <input
                            id="upload-insurance-policy"
                            onChange={(e) => setFile(e.target.files[0])}
                            className="hidden w-full"
                            type="file"
                            disabled={isFileLoading}
                          />
                        </label>
                      </CardFooter>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Instellingen;
