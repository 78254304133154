import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../constant";
import axios from "axios";
import { toast } from "react-toastify";

const Klant = (props) => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [modal, setModal] = useState(false);
  const [voornaam, setVoornaam] = useState("");
  const [achternaam, setAchternaam] = useState("");
  const [adres, setAdres] = useState("");
  const [postcode, setPostcode] = useState("");
  const [stad, setStad] = useState("");
  const [email, setEmail] = useState("");
  const [wachtwoord, setWachtwoord] = useState("");
  const [verwijderenModal, setVerwijderenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [site, setSite] = useState(1);
  const [adminType, setAdminType] = useState("");

  const history = useHistory();

  const ApiConfig = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  const sites = [
    {
      id: 1,
      name: "4-Ward",
    },
    {
      id: 2,
      name: "M4 Mobility",
    },
  ];

  const getBedrijfData = async () => {
    await axios
      .get(`${API_URL}/company/get-my-customers`, ApiConfig)
      .then((res) => {
        setData(res.data);
        setAllData(res.data);
      })
      .catch((e) => {
        toast.error("Kan gebruiker gegevens niet ophalen.");
      });
  };

  const handleMoreInfo = (userId) => {
    history.push(`${process.env.PUBLIC_URL}/klant/klantdetails/${userId}`);
  };

  const bodyParameters = {
    first_name: voornaam,
    last_name: achternaam,
    user_name: voornaam + achternaam,
    email: email.toLowerCase(),
    password: wachtwoord,
    zip_code: postcode,
    address: adres,
    city: stad,
    terms_agree: true, //** Without these two values register is not complete.
    site: parseInt(site) || 1, //** Without these two values register is not complete.
  };

  const search = (value) => {
    filterData(value);
  };

  const excludeColumns = [
    "user_name",
    "id",
    "password",
    "address",
    "zip_code",
    "city",
    "forgot_token",
    "activation_key",
    "is_activated",
    "is_approved",
    "activation_date",
  ];

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(allData);
    else {
      const filteredData = allData.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns?.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  const getData = async () => {
    await axios
      .get(`${API_URL}/superadmin/customers`, ApiConfig)
      .then((res) => {
        setData(res.data);
        setAllData(res.data);
      })
      .catch((e) => {
        toast.error("Kan gebruiker gegevens niet ophalen.");
      });
  };

  useEffect(() => {
    const type = localStorage.getItem("type");
    setAdminType(type);

    if (type == "Bedrijf") {
      getBedrijfData();
    } else {
      getData();
    }
  }, []);

  const deleteUserDefinitive = async () => {
    try {
      const res = await axios.delete(
        `${API_URL}/company/delete-customer/${selectedUserId}`,
        ApiConfig
      );
      if (adminType == "Bedrijf") {
        getBedrijfData();
      } else {
        getData();
      }
      toast.success(res.data.msg);
      setVerwijderenModal(false);
      setSelectedUserId(null);
      getBedrijfData();
    } catch (e) {
      toast.error(e.response.data.msg);
    }
  };

  const tableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Site",
      selector: (row) => (row?.site == 1 ? "Z" : "P"),
      sortable: true,
      center: true,
    },
    {
      name: "Voornaam",
      selector: "first_name",
      sortable: true,
      center: true,
    },
    {
      name: "Achternaam",
      selector: "last_name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },
    {
      name: "Rijbewijs",
      cell: (row, index, column, id) => {
        if (row?.site == 2 && row?.driver_license) {
          return (
            <a
              className="btn btn-primary"
              download={`${row.first_name}_${row.last_name}_invoice_${row.driver_license}`}
              href={`${API_URL}/admin/download-driver-license-admin/${row.driver_license}`}
            >
              <i className="fa fa-download" style={{ color: "white" }}></i>
            </a>
          );
        } else {
          return "-";
        }
      },
      sortable: true,
      center: true,
    },
  ];

  const bedrijfTableColumns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      center: true,
    },
    {
      name: "Site",
      selector: (row) => (row?.site == 1 ? "Z" : "P"),
      sortable: true,
      center: true,
    },
    {
      name: "Voornaam",
      selector: "first_name",
      sortable: true,
      center: true,
    },
    {
      name: "Achternaam",
      selector: "last_name",
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      center: true,
    },

    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="danger"
            size="sm"
            className="my-2 ml-2 px-2 py-2"
            style={{ minWidth: "100px" }}
            onClick={() => {
              setSelectedUserId(row?.id);
              setVerwijderenModal(true);
            }}
          >
            Verwijderen
          </Button>
        );
      },
    },
    {
      name: "",
      cell: (row, index, column, id) => {
        return (
          <Button
            color="transparant"
            size="sm"
            className="my-2 ml-2 px-2 py-2"
            style={{ minWidth: "100px" }}
            onClick={() => handleMoreInfo(row?.id)}
          >
            Meer info
          </Button>
        );
      },
    },
  ];

  const handleRowClicked = (id) => {
    localStorage.setItem("userid", id);
    history.push(`${process.env.PUBLIC_URL}/klant/klantdetails/${id}`);
  };

  const handleNieuweKlant = (e) => {
    e.preventDefault();

    const bodyParameters = {
      first_name: voornaam,
      last_name: achternaam,
      user_name: voornaam + achternaam,
      email: email.toLowerCase(),
      password: wachtwoord,
      zip_code: postcode,
      address: adres,
      city: stad,
      terms_agree: true, //** Without these two values register is not complete.
      site: parseInt(site) || 1, //** Without these two values register is not complete.
    };

    axios
      .post(`${API_URL}/admin/create-customer`, bodyParameters, ApiConfig)
      .then(function (response) {
        setModal(false);
        getData();
        toast.success(
          "Gebruiker is aangemaakt! Deze staat nu bij aanvragen en moet je nog goedkeuren. Email adres staat al als geverifieerd."
        );
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.msg);
      });
  };

  const handleNieuweKlantByBedrijf = (e) => {
    e.preventDefault();

    const bodyParameters = {
      first_name: voornaam,
      last_name: achternaam,
      user_name: voornaam + achternaam,
      email: email.toLowerCase(),
      password: wachtwoord,
      zip_code: postcode,
      address: adres,
      city: stad,
      terms_agree: true, //** Without these two values register is not complete.
      site: parseInt(site) || 1, //** Without these two values register is not complete.
    };

    const user = axios
      .post(`${API_URL}/company/create-customer`, bodyParameters, ApiConfig)
      .then(function (response) {
        setModal(false);
        getBedrijfData();
        toast.success(`Gebruiker is aangemaakt.`);
      })
      .catch(function (error) {
        toast.error(`Er is een fout opgetreden. ${error?.response?.data?.msg}`);
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Gebruiker" title="Gebruikers" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                className="bg-light-primary"
                style={{ padding: "15px" }}
              >
                <Form className="theme-form">
                  <InputGroup className="m-0">
                    <Input
                      onChange={(e) => search(e.target.value)}
                      className="form-control-plaintext px-2"
                      type="search"
                      placeholder="Zoeken op naam of email..."
                    />
                  </InputGroup>
                </Form>
              </CardHeader>
              <CardBody>
                <div className="w-100 d-flex flex-md-row flex-column justify-content-between  text-center">
                  <p>Klik een gebruiker om meer informatie te zien.</p>
                  <button
                    className="btn btn-primary px-md-4 px-2"
                    onClick={() => setModal(true)}
                  >
                    Maak nieuwe gebruiker aan
                  </button>
                </div>
                <DataTable
                  data={data}
                  columns={
                    adminType == "Bedrijf" ? bedrijfTableColumns : tableColumns
                  }
                  empty
                  striped={true}
                  center={true}
                  pointerOnHover={true}
                  persistTableHead
                  pagination={true}
                  onRowClicked={(e) => handleRowClicked(e.id)}
                  noDataComponent={
                    <p style={{ padding: 20 }}>
                      Er zijn geen gebruikers met deze specificatie.
                    </p>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader toggle={() => setModal(false)}>
          Nieuwe gebruiker aanmaken
        </ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          <Form
            onSubmit={(e) =>
              adminType == "Bedrijf"
                ? handleNieuweKlantByBedrijf(e)
                : handleNieuweKlant(e)
            }
          >
            <Row>
              <Col>
                <Input
                  className="form-control"
                  type="text"
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  placeholder="Voornaam"
                  value={voornaam}
                  onChange={(e) => setVoornaam(e.target.value)}
                  required
                />
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Achternaam"
                  value={achternaam}
                  onChange={(e) => setAchternaam(e.target.value)}
                  required
                />
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="password"
                  placeholder="Wachtwoord"
                  value={wachtwoord}
                  onChange={(e) => setWachtwoord(e.target.value)}
                  required
                />
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Woonadres"
                  value={adres}
                  onChange={(e) => setAdres(e.target.value)}
                  required
                />
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Postcode"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  required
                />
                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                  }}
                  className="form-control"
                  type="text"
                  placeholder="Plaats"
                  value={stad}
                  onChange={(e) => setStad(e.target.value)}
                  required
                />

                <Input
                  style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                  className={adminType == "Bedrijf" ? "hidden" : "form-control"}
                  placeholder="Site"
                  type="select"
                  required
                  onChange={(e) => setSite(e.target.value)}
                >
                  {sites?.map((site, index) => (
                    <option key={index} value={site?.id}>
                      {site?.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Button color="primary" style={{ marginTop: 20 }} type="submit">
              Gebruiker aanmaken
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={verwijderenModal}
        toggle={() => setVerwijderenModal(!verwijderenModal)}
      >
        <ModalHeader toggle={() => setVerwijderenModal(!verwijderenModal)}>
          Weet je zeker dat je deze werknemer wilt verwijderen?
        </ModalHeader>
        <ModalBody>
          Als je de werknemer verwijderd kan dit niet meer teruggedraaid worden.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteUserDefinitive()}>
            Verwijderen
          </Button>{" "}
          <Button
            color="grey"
            onClick={() => setVerwijderenModal(!verwijderenModal)}
          >
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Klant;
